import React from "react";
import { observer } from "mobx-react";
import useStore from "../../hooks/useStore";
import FullInput from "../forms/fullInput";

const BillingAddressLine1 = () => {
  const { payment } = useStore();

  const handleChange = event => {
    payment.setPaymentField("billingAddressLine1", event.target.value);
  };

  const handleBlur = event => {
    const { name, value } = event.target;
    payment.setPaymentFieldDirty(name);
    payment.onBlurPaymentFieldValidation(name, value);
  };

  return (
    <FullInput
      title="Billing Address Line 1"
      placeholder="Billing Address Line 1"
      name="billingAddressLine1"
      handleChange={handleChange}
      handleBlur={handleBlur}
      value={payment.billingAddressLine1.value}
      dirty={payment.billingAddressLine1.dirty}
      error={payment.billingAddressLine1.error}
      type="text"
      className="uppercase"
    />
  );
};

export default observer(BillingAddressLine1);
