import React from "react";
import { observer } from "mobx-react";
import useStore from "../hooks/useStore";
import { SEARCH_TYPES } from "../helpers/constants";
import { FullSearchForm, TicketIdSearchForm } from "../components/";

const RenderSearchForm = () => {
  const { search } = useStore();

  const handleSubmit = e => {
    e.preventDefault();
    return search.attemptSearch(SEARCH_TYPES.full);
  };

  const handleChange = e => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    return search.handleSearchChange(fieldName, fieldValue);
  };

  const props = {
    handleChange,
    handleSubmit,
    disabled: search.fetching
  };
  let SearchForm = FullSearchForm;
  switch (search.searchType) {
    default:
    case SEARCH_TYPES.full:
      props.lastName = search.lastName;
      props.plateNumber = search.plateNumber;
      props.plateState = search.plateState;
      SearchForm = FullSearchForm;
      break;
    case SEARCH_TYPES.ticket:
      props.ticketId = search.ticketId;
      SearchForm = TicketIdSearchForm;
      break;
  }
  return <SearchForm {...props} />;
};

export default observer(RenderSearchForm);
