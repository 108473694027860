import React, { useEffect } from "react"
import { observer } from "mobx-react"
import useStore from "../hooks/useStore"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"
import { objectToArray, ADWORDS_LABELS } from "../helpers"
import { CheckoutSummary } from "../components"
import GoogleConversionTracking from "../tracking/googleAdConversion"
import microsoftConversions from "../tracking/microsoftAdConversion"

const SubText = styled.div`
  line-height: 1.4375rem;
  font-size: 1.25rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  max-width: 690px;
  padding: 0.8em 1em 0.25em 1em;
  width: 100%;
`

const SubText2 = styled.div`
  line-height: 1.4375rem;
  font-size: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  max-width: 610px;
  padding: 0.5em 1em 0.25em 1em;
`
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 470px;
  flex-shrink: 1;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 9px 24px rgba(0, 0, 0, 0.1);
`
const Complete = () => {
  const { person, violation, general, search, payment } = useStore()

  const selectedViolations = objectToArray(violation.selected)
  const paidViolations = selectedViolations.filter(violation => {
    // Check if the ticketId of the violation is not in notPaidList
    return !payment.chicagoBotNotPaidList.some(notPaid => notPaid.ticketId === violation.violationNumber)
  })
  const unpaidViolations = selectedViolations.filter(violation => {
    // Check if the ticketId of the violation is not in notPaidList
    return payment.chicagoBotNotPaidList.some(notPaid => notPaid.ticketId === violation.violationNumber)
  })
  if (unpaidViolations.length > 0) {
    violation.resetSelectedViolations()
    paidViolations.forEach(ticket => violation.addOrRemoveViolation(ticket.violationNumber))
  }
  const city = general.location.name.toLowerCase()

  useEffect(() => {
    microsoftConversions.complete()
  })

  const plateNumber = search.plateNumber || selectedViolations[0].licensePlate

  return (
    <Container>
      <Row id="complete-summary-container">
        <Col
          lg={6}
          md={12}
          sm={12}
          className="d-flex flex-column justify-content-start align-items-center"
          id="complete-summary-confirmation"
        >
          <h3 className="pt-1 text-center">
            You’re all set{" "}
            <span className="uppercase">{person.firstName.value}</span>!
          </h3>
          {process.env.REACT_APP_USE_EVERVAULT === "false" ? (
            <h3 className="pt-4 text-center">
              We will process your payment within one business day.
            </h3>
          ) : null}
          {process.env.REACT_APP_USE_EVERVAULT === "true" ? (
            <CardContainer>
              <SubText style={{ margin: "10px 0" }}>
                <strong>What happens next?</strong>
              </SubText>
              <SubText2>
                The City of Chicago will process your payment within 4 business days. During
                this time, your ticket may appear as paid or unpaid, after
                which, your ticket will be satisfied.
              </SubText2>
              <SubText2>
                Note: You will see 3 charges on your card statement, two from
                the City of Chicago and one from us, totaling ${violation.orderTotal.toFixed(2)}.
              </SubText2>
              <br/>
            </CardContainer>
          ) : null}
          <br/>
          {process.env.REACT_APP_USE_BRAINTREE === "true" ? (
            <h5 className="py-3 text-center">
            Confirmation Code:{" "}
              {payment.result.payment.paymentReference.toUpperCase()}
            </h5>
          ) : process.env.REACT_APP_USE_EVERVAULT === "true" && payment.chicagoBotConfirmationID !== "" ? (
            <h5 className="py-3 text-center">
              Confirmation Code:{" "}
              <b>{payment.chicagoBotConfirmationID}</b>
            </h5>
          ) : null}
        </Col>
        <Col lg={6} md={12} sm={12} className="py-1">
          <CheckoutSummary
            text={`${general.location.id}`}
            paidViolations={paidViolations}
            unpaidViolations={unpaidViolations}
            violationsTotal={violation.totalSelectedFines}
            serviceFee={violation.serviceFeeAmount}
            creditCardFee={violation.creditCardFeeAmount}
            totalFees={violation.totalFees}
            total={violation.orderTotal}
            totalAdditionalTicketFee={violation.totalAdditionalTicketFee}
            plateNumber={plateNumber}
            isFinalSummary={true}
          />
          <GoogleConversionTracking
            onSuccess={() => {}}
            onError={err => console.error("GC", err)}
            remarketingOnly={false}
            conversionLabel={ADWORDS_LABELS[city].complete}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default observer(Complete)
