const reactSelectStyle = {
  container: base => ({ ...base, display: "flex", flexGrow: 1, minWidth: 180 }),
  menu: base => ({ ...base, zIndex: 999, left: 0, borderRadius: 0, margin: 0 }),
  option: (base, state) => ({
    ...base,
    fontFamily: "AvenirNext Regular",
    color: state.isSelected ? "white" : "black",
    cursor: "pointer"
  }),
  indicatorSeparator: () => {},
  indicatorsContainer: base => ({
    ...base,
    width: 60,
    zIndex: 100
  }),
  valueContainer: base => ({
    ...base,
    padding: "0 30px 0px 30px",
    fontSize: 16,
    color: "#7f7f7f",
    width: "100%",
    cursor: "text"
  }),
  dropdownIndicator: base => ({
    ...base,
    cursor: "pointer",
    color: "#3089F3",
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 3px 10px 0px rgba(50, 50, 50, 0.15)"
  }),
  control: base => ({
    ...base,
    fontFamily: "AvenirNext Regular",
    backgroundColor: "white",
    borderRadius: 0,
    color: "#7f7f7f",
    border: 0,
    boxShadow: "none",
    height: 75,
    width: "100%"
  }),
  singleValue: base => ({ ...base, color: "#7f7f7f" })
};

export default reactSelectStyle;
