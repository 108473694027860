export const email = emailInput => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(emailInput).toLowerCase());
};

export const phoneNumber = phoneNumberInput => {
  const phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  return phoneNumberPattern.test(phoneNumberInput);
};

export const isNumeric = string => !isNaN(string); //eslint-disable-line

export const zipCode = string => {
  const usZipCodePattern = /^[0-9]{5}$/
  return usZipCodePattern.test(string)
}
