import React from "react";
import { Modal, ModalBody, Fade } from "reactstrap";

import Spinner from "./spinner";
import RUNCYCLE from "../assets/gif/runcycle-small.gif";

const StatusModal = props => (
  <Modal fade centered isOpen>
    <ModalBody className="p5 mx-auto">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h4 className="pt-3 mx-auto text-center">{props.message || `Please Wait...`}</h4>
        <h5 className="pt-3 mx-auto text-center">{props.secondMessage || ``}</h5>
        <Fade in timeout={300}>
          {props.spinner ? (
            <Spinner />
          ) : (
            <img src={RUNCYCLE} alt="Running Animation" />
          )}
        </Fade>
      </div>
    </ModalBody>
  </Modal>
);

export default StatusModal;
