import { getRoot, types } from "mobx-state-tree";
import {
  SEARCH_TYPES,
  CITIES,
  checkCookiesDisabled,
  getCity
} from "../helpers";
import GoogleAnalytics from "../tracking/google";

const currentCity = getCity();

const SearchStore = types
  .model("SearchStore", {
    searchType: SEARCH_TYPES.full, // by plate or ticket id
    recaptchaToken: types.maybeNull(types.string), // google recaptcha && must be sent to backend
    fetching: false, // fetching violations
    lastName: "",
    plateNumber: "",
    plateState: CITIES[currentCity].state,
    ticketId: ""
  })
  .actions(self => {
    return {
      resetSearch() {
        self.searchType = SEARCH_TYPES.full;
        self.recaptchaToken = "";
        self.fetching = false;
        self.lastName = "";
        self.plateNumber = "";
        self.plateState = CITIES[currentCity].state;
        self.ticketId = "";
      },

      loadSearchState(state) {
        for (let key of state) {
          self[key] = state[key];
        }
      },

      validationError(title, message) {
        self.setFetchingStatus(false);
        self.setRecaptchaToken(null);
        const { general } = getRoot(self);
        general.setError({ title, message });
      },

      setFetchingStatus(fetchingBoolean) {
        self.fetching = fetchingBoolean;
      },
      setRecaptchaToken(recaptchaToken) {
        self.recaptchaToken = recaptchaToken;
      },
      setSearchType(searchType) {
        self.searchType = searchType;
      },

      handleSearchChange(fieldName, fieldValue) {
        self[fieldName] = fieldValue;
      },

      submitSearch() {
        const { general } = getRoot(self);
        general.clearError();
        const city = general.location;

        let search = {
          lastName: self.lastName,
          plateNumber: self.plateNumber,
          plateState: self.plateState
        };

        if (self.searchType === SEARCH_TYPES.ticket) {
          search = { ticketId: self.ticketId };
        }

        // trim here
        // eslint-disable-next-line
        for (const key in search) {
          // eslint-disable-next-line
          if (search.hasOwnProperty(key)) {
            if (key === "plateNumber" || key === "ticketId") {
              search[key] = search[key].trim().replace(/\s+/g, "");
            } else {
              search[key] = search[key].trim();
            }
            self.handleSearchChange(key, search[key]);
          }
        }

        self.setFetchingStatus(true);

        GoogleAnalytics.action.searchTickets(self.searchType);

        // form validation
        const title = "Missing Required Detail";
        if (self.searchType === SEARCH_TYPES.full) {
          // We keep lastName/businessName validation for Chicago only.
          if (city.name === "Chicago" && self.lastName.length < 2) {
            const message = `A last name or business name must be 2 or more characters long.`;
            GoogleAnalytics.action.searchValidation(message);
            return self.validationError(title, message);
          }
          if (self.plateNumber.length < 4) {
            const message = `A license plate number must be 4 or more characters long.`;
            GoogleAnalytics.action.searchValidation(message);
            return self.validationError(title, message);
          }
          if (!self.plateState.length) {
            const message =
              "Please select the state that issued your license plate.";
            GoogleAnalytics.action.searchValidation(message);
            return self.validationError(title, message);
          }
        }
        // if theres no captcha token and one is required block search
        if (
          (!self.recaptchaToken || !self.recaptchaToken.length) &&
          process.env.REACT_APP_RECAPTCHA_SITE_ID
        ) {
          return self.validationError(
            "Captcha required",
            "Please check the captcha box to perform the search."
          );
        }
        // We keep ticketId validation for Chicago only.
        if (
          city.name === "Chicago" &&
          self.searchType === SEARCH_TYPES.ticket &&
          (isNaN(self.ticketId) || //eslint-disable-line
            self.ticketId.length !== 10)
        ) {
          const message = "A valid ticket number is exactly 10 digits long.";
          GoogleAnalytics.action.searchValidation(message);
          return self.validationError(title, message);
        }
        if (
          self.searchType === SEARCH_TYPES.ticket &&
          !isNaN(self.ticketId) && //eslint-disable-line
          self.ticketId.length === 10
        ) {
          self.handleSearchChange("ticketId", self.ticketId.trim());
        }

        const { violation } = getRoot(self);
        return violation.requestViolations(search);
      },

      attemptSearch() {
        checkCookiesDisabled().then(cookiesDisabled => {
          if (cookiesDisabled) {
            const { general } = getRoot(self);
            return general.cookieChecker();
          }
          return self.submitSearch();
        });
      }
    };
  });

export default SearchStore;
