import React from "react";
import PropTypes from "prop-types";

import "./searchForms.css";

const TicketIdSearchForm = ({
  disabled,
  handleSubmit,
  handleChange,
  ticketId,
}) => (
  <form onSubmit={handleSubmit} className="d-inline-flex flex-grow-1">
    <div className="search-form-container">
      <input
        className="search-form-input"
        disabled={disabled}
        value={ticketId}
        onChange={handleChange}
        type="text"
        name="ticketId"
        id="ticketId"
        placeholder="Summons Number"
      />
      <button
        className="search-form-submit"
        disabled={disabled}
        onClick={handleSubmit}
      >
        Search Tickets
      </button>
    </div>
  </form>
);

TicketIdSearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  ticketId: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default TicketIdSearchForm;
