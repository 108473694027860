import { observer } from "mobx-react";
import React from "react";
import useStore from "../hooks/useStore";

const TermsComponent = () => {
  const { general } = useStore();
  return (
    <div>
      <div className="row pl-4 pr-4 pb-3">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <p className="opacity-85">
            {general.location.domainCamelCase} is operated and run by WinIt,
            LLC. We here at Winit, LLC (together with our affiliates, “Winit”,
            “us”, “our” or “we”) would like to thank you for your interest in
            our website and mobile applications and our related Services (as
            defined below) (collectively, our “Platform”). The terms and
            conditions set forth herein and any other document incorporated
            herein by reference (collectively, these “Terms”), together with our{" "}
            <a href="https://www.appwinit.com/privacy-policy">privacy policy</a>{" "}
            (our “Privacy Policy”) govern your use of our Platform we make
            accessible thereby, so please read them thoroughly before accessing
            or using our Platform.
          </p>

          <p className="opacity-85">
            BY ACCESSING AND USING OUR PLATFORM, YOU (A) ACKNOWLEDGE THAT YOU
            HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS AND OUR
            PRIVACY POLICY; (B) REPRESENT THAT YOU ARE OF LEGAL AGE TO ENTER
            INTO A BINDING CONTRACT UNDER THE APPLICABLE LAW, AND THAT YOU HAVE
            THE RIGHT, AUTHORITY AND CAPACITY TO ENTER INTO AND ABIDE BY THESE
            TERMS AND OUR PRIVACY POLICY; AND (C) AGREE THAT YOU ARE LEGALLY
            BOUND BY THESE TERMS AND OUR PRIVACY POLICY. IF YOU DO NOT AGREE TO
            THESE TERMS AND OUR PRIVACY POLICY, DO NOT USE OUR PLATFORM. IF YOU
            ARE USING OUR PLATFORM ON BEHALF OF ANY ENTITY, THEN YOU ARE
            AGREEING TO THESE TERMS AND OUR PRIVACY POLICY ON BEHALF OF THAT
            ENTITY.
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            If you do not accept these Terms and Our Privacy Policy, you are not
            authorized to use our Platform. We reserve the right to modify these
            Terms and Our Privacy Policy at any time and such modification will
            be effective upon posting to our Platform (whether on our website or
            via our mobile applications) and/or provision of email notice to you
            thereof. Your continued use of our Platform and/or Services
            following any such posting or notification of the revised Terms
            shall be deemed to constitute your acceptance of any such
            modifications. Please review these Terms periodically for changes
            and pay attention to the “Last Updated” date displayed at the bottom
            of this page.
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            1. SERVICES; OUR PLATFORM
          </p>

          <p className="opacity-85">
            Subject to Your acceptance of and compliance with these Terms and
            with the applicable payment requirements for the Services, Winit
            hereby grants you a limited, non-exclusive, non-transferable,
            non-sublicensable, revocable right and license to access and use our
            Platform, solely in accordance with these Terms. Our Platform is
            designed to help you and other users to: (i) challenge parking
            violation tickets before the City of {general.location.name}, as
            well as in any other cities in which Winit may operate, from time to
            time (collectively, the “Parking Services”); (ii) quickly and easily
            connect and communicate with attorneys or law firms with relevant
            legal expertise in traffic violations (collectively, “attorneys”) to
            challenge traffic violations tickets (collectively, the “Traffic
            Services”, and together with the Parking Services, the “Services”),
            and (iii) process payments for both.
          </p>

          <p className="opacity-85">
            To access our Platform, you will be asked to register and maintain
            an active user account (your “Account”), which includes, among other
            things, your credit card information and/or other valid payment
            method, and those license plate number(s) you choose to link to your
            Account (your “Plate Number(s)”).
          </p>

          <p className="opacity-85">
            You hereby acknowledge, understand and agree that your use of our
            Platform and Services is intended for Your personal, non-commercial
            use, unless otherwise previously authorized by us in writing.
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            2. DISCLAIMER OF ATTORNEY-CLIENT RELATIONSHIP
          </p>

          <p className="opacity-85">
            <b style={{ fontFamily: "AvenirNext bold" }}>
              Winit (i) is not a law firm; (ii) does not perform legal services
              that an attorney performs; and (iii) is not permitted to engage in
              the practice of law, including without limitation providing any
              kind of opinion or recommendation about possible legal rights,
              defenses or strategies. You hereby acknowledge, understand and
              agree that Winit is not, under any circumstances your lawyer or
              legal representative or counsel in any way.
            </b>{" "}
            No attorney-client relationship between Winit and any of its users,
            including you, is created by using our Platform. All information you
            provide us, while protected as expressly provided under our{" "}
            <a href="https://www.appwinit.com/privacy-policy">Privacy Policy</a>
            , is not protected by attorney-client privilege nor is it deemed
            attorney work product.
          </p>

          <p className="opacity-85">
            Any attorney-client relationship formed by you and any Service
            Provider (as defined below) pursuant to the Services, including
            without limitation the Traffic Services, is between you and such
            Service Provider and not between Winit and you. Winit is designed to
            facilitate communications between its users and Service Providers
            but does not endorse any particular attorney or other Service
            Provider and shall not be held responsible for the quality or
            accuracy of any legal services provided by any attorney or other
            Service Provider. If you choose to initiate a relationship with an
            attorney or other Service Provider via our Platform, you do so at
            your sole discretion.&nbsp; Winit does not assume, nor will Winit
            have, any liability or responsibility to you or any other person in
            connection thereto. Any attorney or other Service Provider retained
            by you through our Platform will only be obligated to provide legal
            representation in connection with the parking or traffic violation
            ticket(s) specifically provided by you (whether or not via our
            Platform).{" "}
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            3. USE OF THE PARKING SERVICES
          </p>

          <p className="opacity-85">
            <u>Submitting your Parking Tickets</u>. We recommend you submit your
            parking ticket through our Platform as soon after receipt thereof as
            possible, as {general.location.name} imposes specific
            pleading/response deadlines for parking violations. If you fail to
            submit the tickets through our Platform at least seven (7) business
            days prior to such deadline, you, and not Winit, shall be solely
            responsible for any additional costs, including late fees, incurred
            as a result of your delay.
          </p>

          <p className="opacity-85">
            <u>Service Providers</u>. The Parking Services are made available by
            Winit, via its partners, affiliates, contractors, representatives
            and agents (collectively, our “Service Providers”). By using the
            Parking Services, you authorize our Service Providers to represent
            you at the NYPVB and any other applicable municipal, governmental or
            other authority on all matters concerning any parking tickets you
            submitted via our Platform, or those parking tickets received by us
            through our Auto-Dispute Program (as defined below).
          </p>

          <p className="opacity-85">
            <u>Standstill Provision</u>. Once a parking ticket is submitted
            through our Platform, you hereby expressly, unconditionally and
            irrevocably agree: (i) not to take any course of action that will
            directly or indirectly disrupt, intervene, or circumvent our Service
            Providers’ and our efforts on your behalf, (ii) not to take any
            action related to any of your parking tickets without our prior
            written consent, until Winit notifies you of either: (1) its
            decision not to dispute Your parking ticket(s), in which case you
            shall be solely responsible to dispute it or pay the underlying
            fine, or (2) the NYPVB’s (or other applicable authority’s) decision
            with respect to your parking ticket(s).{" "}
            <b style={{ fontFamily: "AvenirNext bold" }}>
              Any violation of this provision shall subject you to a
              cancellation fee of Five Dollars ($5.00), which may be imposed in
              our sole discretion;
            </b>{" "}
            and (ii) that our Service Providers and we are entitled to take any
            action reasonably deemed necessary or appropriate in the course of
            disputing your parking ticket.
          </p>

          <p className="opacity-85">
            <u>Auto Dispute Program; Ticket Fetcher</u>. You may, either upon
            initial establishment of your Account, or at any time thereafter,
            choose at your sole discretion, to opt-in to our “Auto Dispute
            Program,” whereby you authorize Winit and our Service Providers to
            periodically search the NYPVB database for parking tickets linked to
            your Plate Numbers and automatically dispute any future parking
            tickets found, which are linked to your Plate Number(s). All such
            auto-disputes shall be undertaken in accordance with these Terms.
            You have the right, at any time, to opt-out of our Auto Dispute
            Program by selecting the option to turn off “Auto Dispute” under
            your Account settings. You hereby acknowledge and agree that it is
            your sole responsibility to turn off the “Auto Dispute Program”
            option&nbsp;if you are not interested in such service, and in such
            case, we shall not dispute future parking tickets&nbsp;linked to
            your&nbsp;Plate Number&nbsp;without your prior approval (which shall
            be made either via submission of a specific parking ticket or via
            your opt-in to our Auto Dispute Program).
          </p>

          <p className="opacity-85">
            Winit processes a considerable amount of parking tickets through our
            Platform that are being handled by our Service Providers before the
            NYPVB at any given time. Responding to individual requests regarding
            the status and the details of any one specific case is prohibitive.
            Therefore, Winit will not respond to individual inquiries regarding
            the details or specifics regarding any particular parking ticket,
            hearings or decisions related thereto. The “Ticket Fetcher” feature
            on our Platform enables you to manually scan the NYPVB database to
            check the status of your pending parking tickets or to verify
            whether a new parking ticket has been issued to your Plate
            Number(s). We recommend all users of our Platform to periodically,
            independently check the NYPVB database for new parking tickets
            associated with your Plate Number(s) and follow up on the status of
            their pending tickets with the NYPVB directly.
          </p>

          <p className="opacity-85">
            Please note that the Auto Dispute Program (which includes, without
            limitation, our plate monitoring program) and Ticket Fetcher
            features are available solely for your convenience and neither Winit
            nor our Service Providers can guaranty that all parking tickets
            linked to your Plate Number(s) shall be accurately and fully
            obtained or displayed via our Platform. WINIT MAKES NO
            REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THE COMPLETENESS OR
            ACCURACY OF THE INFORMATION OBTAINED USING OUR PLATFORM OR ANY
            RELATED FEATURES OR PROGRAMS.
          </p>

          <p className="opacity-85">
            <u>Cancellation Policy</u>. Once a parking ticket is submitted
            through our Platform, you may not cancel or reverse your request for
            the Parking Services in connection with such parking ticket without
            our prior written consent. If you submit a parking ticket in error,
            please notify our customer support by{" "}
            <a href="mailto:contact@appwinit.com">email</a> as early as
            possible, but in no case later than six (6) hours after your
            submission thereof. Your failure to notify us within this timeframe
            will be deemed acceptance by our Platform of your intent to submit
            your parking ticket(s).
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            4. TRAFFIC SERVICES
          </p>

          <p className="opacity-85">
            <u>Submitting your Traffic Violation Tickets</u>. We recommend you
            submit your traffic violation ticket through our Platform as soon
            after receipt thereof as possible, as New York City imposes specific
            pleading/response deadlines for such violations. If you submit a
            traffic violation ticket to our Platform with an expired (or
            imminent) hearing date, you increase the risk that your driver’s
            license will be suspended. Failure to submit traffic violations to
            our Platform in a timely manner will render you, and not Winit,
            solely responsible for any additional costs, including late fees,
            incurred as a result of your delay.
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            5. FEES
          </p>

          <p className="opacity-85">
            <u>General</u>. You hereby agree to maintain current, valid and
            accepted method of payment on your Account throughout the term of
            your use of our Platform. All fees payable by you hereunder are
            exclusive of applicable taxes and duties. By establishing your
            Account and using our Platform, you authorize Winit to automatically
            charge your Account for all applicable charges incurred in
            connection with the Services. At all times, you remain solely
            responsible for all charges incurred under your Account, regardless
            of whether or not you were aware of such charges or their respective
            amounts at the time they were incurred (for example, under the Auto
            Dispute Program).
          </p>

          <p className="opacity-85">
            <u>Parking Services</u>. Winit collects payment for the Parking
            Services only upon successfully resolving your parking ticket in
            your favor. In the event that the Parking Services lead to a
            successful dismissal of your parking ticket or reduction in the
            amount of the fine, you hereby expressly authorize Winit to charge
            your Account in an amount equal to fifty percent (50%) of the
            difference between the amount you originally owed on your parking
            ticket (including any applied late fee charges and penalties) and
            the final amount of the fine owed following the provision of the
            Parking Services.
          </p>

          <p className="opacity-85">
            For example, let’s say your parking ticket fine is $100, but you
            submitted it to our Platform late and your parking ticket had
            incurred a $10 late fee charge (so the total balance is $110):
          </p>

          <ul className="opacity-85">
            <li>
              If Winit does not manage to successfully dispute your parking
              ticket:{" "}
              <b style={{ fontFamily: "AvenirNext bold" }}>
                You are charged $0.00
              </b>
            </li>
            <li>
              If Winit successfully disputes the only the late fee:{" "}
              <b style={{ fontFamily: "AvenirNext bold" }}>
                You are charged $5.00
              </b>
            </li>
            <li>
              If Winit successfully disputes your parking ticket and it is fully
              dismissed:{" "}
              <b style={{ fontFamily: "AvenirNext bold" }}>
                You are charged $55.00
              </b>
            </li>
          </ul>

          <p className="opacity-85">
            In the event that Winit is unsuccessful in its efforts to have your
            parking ticket completely dismissed, you may, at your election, pay
            your parking ticket through our Platform using our Pay Now feature
            (found on our Platform), whereupon Winit pays the NYPVB directly on
            your behalf. An additional processing fee of 3% of the amount of the
            fine, plus a $2.99 fixed fee, will be charged to your Account
            (collectively, the “Processing Fee”). Payments processed by us via
            our Pay Now feature are non-refundable under any circumstances, as
            we automatically pay on your behalf (after your election to use the
            feature) all related parking tickets and any related Processing Fees
            (as described in the “Fees” Section below). All Fees are subject to
            change in Winit’s sole discretion.
          </p>

          <p className="opacity-85">
            <u>Traffic Services</u>. With respect to the Traffic Services, all
            users of our SErvices are required to pay a one-time Platform usage
            fee per ticket, which fee may be modified from time to time in our
            sole discretion. The Platform usage fee will be processed by Winit
            at the time you upload your ticket information onto the Platform.
            Moreover, once you choose the attorney you would like to work with
            from all available, participating attorneys on our Platform, you
            will enter into a direct agreement with such attorney to effectuate
            the engagement. The fixed legal fees you pay through our Platform in
            connection with the Traffic Services are passed through to the
            attorney in full once you appoint the attorney. You understand,
            acknowledge and agree that once you appoint an attorney through our
            Platform, Winit will charge, through the payment method on the
            Account, the applicable legal fee and any other payments, as may be
            instructed by you or your attorney.
          </p>

          <p className="opacity-85">
            Please note that if you missed your court date or otherwise fail to
            appear on the scheduled date you may be required to post a bond
            (usually $40 per ticket) to reschedule you trial, and you hereby
            authorize Winit to automatically charge any such amount upon your
            attorney’s instructions. At the completion of your trial you will
            receive the bond money back if you are found not guilty or the bond
            will be applied toward any fines you owe if you are found guilty.
          </p>

          <p className="opacity-85">
            Although a transaction with the attorney is processed on your behalf
            through our Platform, Winit is simply an intermediary party in such
            transaction. Winit has no liability, either primarily or
            secondarily, for paying the attorney or any third party, other than
            through our Platform on your behalf. Attorneys participating in our
            Platform may pay a certain usage fee or subscription fee to Winit as
            a separate transaction, whether directly or through our Platform.
          </p>

          <p className="opacity-85">
            <u>Payment Failure</u>. In the event that Winit is unable to process
            a payment fee or payment is canceled, Winit shall charge you a Five
            Dollar ($5.00) service fee per each failed payment attempt, all in
            accordance with applicable laws and regulations. Any delinquent or
            late payments shall also incur an interest at a rate of one and a
            half (1.5%) per month as of due date, or the maximum lesser amount
            permitted by applicable law. You will also be solely liable for any
            related “insufficient fund” charges or other penalties which may be
            imposed by your bank. If we are unable to process payment, the
            Services will immediately cease unless we notify you otherwise, and
            you will be responsible to pay for both your ticket and our service
            fees directly. In addition to the amount(s) due Winit, delinquent
            accounts and/or chargebacks will be charged with fees and/or charges
            that are incidental to the collection of delinquent accounts and/or
            chargebacks, including without limitation, collection fees,
            convenience fees and/or other third party charges. Without limiting
            the generality of the foregoing, Winit reserves the right to charge
            interest at an annualized rate of 18% or the maximum rate permitted
            by applicable law, whichever is higher. You hereby expressly agree
            that all communications in relation to delinquent accounts will be
            made by electronic mail or by phone, as provided to Winit by you.
            Such communications may be made by Winit or by any third party on
            its behalf, including without limitation, a third party collection
            agent. The remedies stipulated in this Section 5 shall not derogate
            from any other right or remedy available to us by law or under these
            Terms.
          </p>

          <p className="opacity-85">
            If the balance is not paid your account will be suspended and sent
            to a Third-Party Collection Agency. You will be responsible for
            paying all Collection Fees assessed by the Third Party Collection
            Agency. You agree, in order for Winit to service your account or to
            collect any amount you may owe, unless otherwise prohibited by
            applicable law, Winit or a designated Third Party Collection Agency
            are authorized to (i) contact you by telephone at the telephone
            number(s) in the contact account information you are providing,
            including wireless telephone numbers, which could result in charges
            to you, (ii) contact you by sending text messages (message and data
            rates may apply) or emails, using any email address you provide
            and/or (iii) methods of contact may include using
            pre-recorded/artificial voice message and/or use of an automatic
            dialing device, as applicable.
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            6. PROHIBITED USES
          </p>

          <p className="opacity-85">
            You may use our Platform and Services only (i) if you are eighteen
            (18) years of age or older and are of the age of consent in your
            jurisdiction, and (ii) for lawful purposes and in accordance and
            full compliance with any and all applicable international, federal,
            state, municipal, local or other laws or regulations.
          </p>

          <p className="opacity-85">You hereby further undertake not to:</p>

          <ul className="opacity-85">
            <li>
              Disable, damage, impede, impair, violate or interfere with the
              functioning of our Platform, its security, its related servers or
              networks, or any other party’s use of our Platform, whether by the
              use of malware or otherwise, or otherwise create a denial of
              service, or falsify the origin of a user’s communications;
            </li>
            <li>
              alter, damage or delete any material appearing on our Platform or
              Services;
            </li>
            <li>
              Reverse engineer, disassemble, decode or otherwise attempt to
              derive or gain access to the source code of any software embodied
              within our Platform or any part thereof;
            </li>
            <li>
              Rent, lend, sell, sublicense, assign, publish, transfer or
              otherwise make available our Platform or any features thereof, to
              any third party, for any reason;
            </li>
            <li>
              Remove, circumvent or otherwise create or implement any workaround
              to any copy protection, rights management or security features in
              or protecting our Platform;
            </li>
            <li>
              Threaten, harass, stalk, spam, abuse, or otherwise violate the
              legal rights (including rights of privacy and publicity) of other
              users; and/or
            </li>
            <li>
              Otherwise engage or cause any third party to engage in any conduct
              that restricts or inhibits anyone’s use or enjoyment of our
              Platform, infringes any third party rights, or which, as
              reasonably determined by us, may expose Winit or the users of our
              Platform to any liability.
            </li>
          </ul>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            7. TERM AND TERMINATION
          </p>

          <p className="opacity-85">
            <u>Account Closure</u>. Winit reserves the right at any time, in its
            sole discretion and without notice to you (subject to the “Pending
            Tickets” Section below), to terminate your license to use our
            Platform (and all other rights granted to you herein), and to block
            or prevent your access to and use of our Platform. You may terminate
            your license to use our Platform at any time and for your
            convenience, by notifying us in writing of your decision (or by
            otherwise following our Account closure procedures which are
            available on the App); provided, however, that such termination is
            not permitted and shall not apply to any ticket(s) that you
            previously submitted through our Platform with respect to which the
            Services are still provided (collectively “Ongoing Services”).
          </p>

          <p className="opacity-85">
            <u>Pending Tickets</u>. Please note that notwithstanding the
            parties’ respective termination rights, you will still be bound by
            these Terms (including any and all payment obligations) in
            connection with Ongoing Services. Winit and our Service Providers
            shall continue to support such Ongoing Services unless Winit has
            notified you otherwise of its choice, at its sole discretion, to
            cease such provision of Ongoing Services. Notwithstanding the
            foregoing, Winit may cease its involvement in connection with any
            Ongoing Services, at any time without notice, effective immediately
            if: (i) you violate any of these Terms; (ii) you fail to maintain a
            valid payment method in your Account; and/or you are otherwise in
            default of any payment obligation with respect the Services.
          </p>

          <p className="opacity-85">
            <u>Effect of Termination</u>. Upon termination: (i) all rights
            granted to you under these Terms will be terminated; (ii) you must
            cease all use of our Platform and may not submit new tickets; (iii)
            you will remain liable for all fees, charges and any other
            obligations you have incurred through the date of termination with
            respect to the Services (including in connection with Ongoing
            Services in accordance with the “Pending Tickets” Section above).
            For the avoidance of doubt, termination of your use of our Platform
            and/or our Services will not limit in any way and of Winit’s rights
            or remedies at law or in equity, all of which are expressly reserved
            hereby.
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            8. THIRD PARTY MATERIALS.
          </p>

          <p className="opacity-85">
            Our Platform may display, include or make available third-party
            content (including information, and other materials) or provide
            links to third-party websites or services, including through
            advertising (collectively, “Third Party Materials”). You acknowledge
            and agree that Winit is not responsible for Third Party Materials,
            including their accuracy, completeness, timeliness, validity,
            copyright compliance, legality, decency, quality or any other aspect
            thereof. Winit does not assume and will not have any liability or
            responsibility to you or any other person or entity for any Third
            Party Materials. Third Party Materials and links thereto are
            provided solely as a convenience to you and you access and use them
            at entirely at your own risk and subject to such third parties’
            terms and conditions.
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            9. DISCLAIMERS
          </p>

          <p className="opacity-85">
            NEITHER WINIT NOR ANY OF ITS SERVICE PROVIDERS MAKES ANY WARRANTY,
            GUARANTEE OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
            SECURITY, QUALITY, SUITABILITY, ACCURACY OR AVAILABILITY OF OUR
            PLATFORM OR THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER
            WINIT NOR ITS SERVICE PROVIDERS REPRESENTS OR WARRANTS RESULTS OF
            THE USE OF OUR PLATFORM, THAT OUR PLATFORM, ITS CONTENT OR ANY OF
            THE SERVICES WILL BE ERROR-FREE OR UNINTERRUPTED, THAT OUR WEBSITE
            OR APPLICATION ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR
            THAT OUR PLATFORM OR ANY OF THE SERVICES WILL MEET YOUR
            EXPECTATIONS. WINIT DOES NOT MAKE ANY REPRESENTATIONS, WARRANTIES
            OR&nbsp;GUARANTEES CONCERNING THE INTEGRITY, COMPETENCE,
            QUALIFICATIONS, STANDING, STATUS, SUITABILITY, COMPLIANCE WITH
            PROFESSIONAL RULES, ACCURACY OR LEGALITY OF ADVICE OF ATTORNEYS, OR
            THE OUTCOME, QUALITY, OR COMPLETION OF THEIR REPRESENTATION. WINIT
            HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
            IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
            WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR
            PARTICULAR PURPOSE.
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            10. LIMITATION ON LIABILITY
          </p>

          <p className="opacity-85">
            YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY
            LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF OUR
            PLATFORM AND THE SERVICES REMAINS WITH YOU. IN NO EVENT SHALL WINIT
            OR ANY OF ITS MANAGERS, OFFICERS, MEMBERS, EMPLOYEES,
            REPRESENTATIVES, AGENTS OR OUR SERVICE PROVIDERS, BE LIABLE FOR ANY
            SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES, WHETHER INCURRED
            DIRECTLY OR INDIRECTLY, INCLUDING ANY LOSS OF PROFITS OR ANTICIPATED
            SAVINGS, ANY EMOTIONAL DISTRESS, LOSS OF DATA, USE, GOODWILL, OR
            OTHER MONETARY OR INTANGIBLE LOSSES, RESULTING FROM: (1) YOUR ACCESS
            TO OR USE OF (OR INABILITY TO ACCESS OR USE) OUR PLATFORM OR
            SERVICES; (2) ANY CONTENT SUBMITTED TO OR ACCESSED THROUGH OUR
            PLATFORM; (3) ANY SERVICES PROVIDED ON OR OBTAINED THROUGH OUR
            PLATFORM; OR (4) ANY COMMUNICATIONS OR DEALINGS WITH, OR CONDUCT OF
            OTHER USERS, OUR SERVICE PROVIDERS AND THE ATTORNEYS.
          </p>

          <p className="opacity-85">
            IN THE EVENT THAT, NOTWITHSTANDING THE FOREGOING DISCLAIMERS AND
            LIMITATIONS, WE SHALL BE HELD LEGALLY LIABLE FOR LOSS OR DAMAGE, TO
            THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF WINIT,
            (INCLUDING ITS MANAGERS, OFFICERS, MEMBERS, EMPLOYEES, SERVICE
            PROVIDERS, REPRESENTATIVES OR AGENTS), FOR ANY CLAIM ARISING OUT OF
            OR RELATING TO THE USE OF THE SERVICES OR OUR PLATFORM, IS LIMITED
            TO THE AMOUNT YOU PAID US FOR THE PARKING SERVICES IN THE 12 MONTH
            PERIOD PRECEDING THE FILING OF SUCH CLAIM (EXCLUDING, FOR THE
            AVOIDANCE OF ANY DOUBT, ANY AMOUNT PAID TO THE ATTORNEYS THROUGH OUR
            PLATFORM FOR WHICH WE ACTED SOLELY AS AN INTERMEDIARY PARTY). YOU
            AGREE THAT LIMITATION AND DISCLAIMERS PROVIDED IN THESE TERMS ARE
            REASONABLE AND WERE TAKEN INTO CONSIDERATION WHEN USING OUR
            PLATFORM. THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT
            BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            11. FORCE MAJEURE
          </p>

          <p className="opacity-85">
            In addition to applicable disclaimers identified above, our
            incomplete performance or failure to perform our duties under these
            Terms will not be considered a breach of these Terms and shall be
            fully excused in the event of interruption and/or delay due to
            causes beyond our reasonable control, such as acts of God, acts of
            any government, war or other hostility, civil disorder, natural
            disasters (including without limitation, the elements, fire, and
            earthquake), explosion, acts of terrorism, power failures, equipment
            failure, industrial or labor disputes, acts of third party
            information providers, third party software errors or failures or
            communications interruptions or failures and all other events beyond
            our reasonable control.
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            12. INDEMNIFICATION
          </p>

          <p className="opacity-85">
            You agree to defend, indemnify and hold harmless Winit, our
            affiliates, independent contractors, Service Providers and
            consultants, and each of their respective officers, employees,
            agents, affiliates, successors and assigns, from and against any
            claims, damages, costs, liabilities and expenses (including, but not
            limited to, reasonable attorneys’ fees) arising out of or related to
            (i) your use of or inability to use our Platform and/or the
            Services; (ii) your breach of violation of these Terms, any
            applicable law or the rights of any third party; and/or (iii) your
            gross negligence, fraud and/or willful misconduct.
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            13. PROMOTIONS; REFERRAL PROGRAMS
          </p>

          <p className="opacity-85">
            Winit, at its sole discretion, may make available promotions with
            different features to any users or prospective users. Winit reserves
            the right to withhold or deduct credits or benefits obtained through
            a promotion in the event that Winit determines that the redemption
            of the promotion or receipt of the credit or benefit was in error,
            fraudulent, illegal, or in violation of the applicable promotion
            terms. From time to time, Winit may offer you incentives to refer
            new users to our Platform (the “Referral Program”). Your
            participation in the Referral Program is subject to these Terms and
            any additional Referral Program terms and conditions that Winit
            shall adopt and implement from time to time.
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            14. INTELLECTUAL PROPERTY.
          </p>

          <p className="opacity-85">
            Our Platform, and the information and material therein, including
            our Platform’s features, functionality and content are the exclusive
            property of Winit, its licensor and/or providers of Third Party
            Materials and are protected by copyright or other intellectual
            property laws. Using our Platform and/or the Services does not give
            you ownership of any intellectual property rights in our Platform or
            the content you access. In the event you elect to communicate to us
            suggestions for improvements in connection with our Platform or the
            Services, Winit shall own all right, title, and interest in and to
            the same, and shall be entitled to use them without restriction.
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            15. COPYRIGHT POLICY
          </p>

          <p className="opacity-85">
            We respect the rights of owners of intellectual property and expect
            our users to conduct themselves accordingly.&nbsp;If you believe
            that any information or material violates these Terms, infringes
            upon your copyrights or otherwise should be removed, you may submit
            a notification pursuant to the Digital Millennium Copyright Act
            (”DMCA”), please immediately notify us by email (Subject line: “DMCA
            Takedown Request”) or by mail at the address specified below and
            include the following information (see 17 U.S.C 512(c)(3) for
            further detail): (i) Identification of the copyrighted work(s) or
            material claimed to have been infringed or to be the subject of
            infringing activity and that is to be removed (provide information
            reasonably sufficient to permit us to locate the material); (ii) A
            physical or electronic signature of the person authorized to act on
            behalf of the owner of the right that is allegedly infringed; (iii)
            Information reasonably sufficient to permit us to contact you, such
            as an address, telephone number, and, if available, an electronic
            mail; (iv) A statement that you have a good faith belief that use of
            the material in the manner complained of is not authorized by the
            copyright owner, its agent, or the law; and (v) A statement that the
            information in the notification is accurate, and under penalty of
            perjury, that you are authorized to act on behalf of the owner of an
            exclusive right that is allegedly infringed.&nbsp;You acknowledge
            that if you fail to comply with all of the requirements of this
            paragraph, your DMCA notice may not be valid.
          </p>

          <p className="opacity-85">
            If you believe that any content provided by you was improperly
            removed (or to which access was disabled) and is not infringing, or
            that you have the authorization from the copyright owner or its
            agent, or by law, to post and use such content, you may send us a
            counter-notice containing the following information: (i) Your name,
            address, telephone number, and e-mail address and your physical or
            electronic signature; (ii) Identification of the content that has
            been removed or to which access has been disabled and the location
            at which the content appeared before it was removed or disabled;
            (iii) A statement that you have a good faith belief that the content
            was removed or disabled as a result of mistake or a
            misidentification of the content; and (iv) a statement that you
            consent to the jurisdiction of the federal court in New York, New
            York, and that you will accept service of process from the person
            who provided notification of the alleged infringement.&nbsp; We have
            the right but not the obligation to send a copy of such
            counter-notice to the original complaining party informing them that
            we may replace the removed content or cease disabling it in ten (10)
            business days.&nbsp; Unless the copyright owner files an action
            seeking a court order against the content provider, member or user,
            the removed content may be replaced, or access to it restored, in
            ten (10) to fourteen (14) business days or more after receipt of
            such counter-notice, at our sole discretion.
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            16. UPDATES
          </p>

          <p className="opacity-85">
            Winit may, from time to time, in its sole discretion develop and
            provide mobile application updates, which may include upgrades, bug
            fixes, patches and other error corrections and/or new features
            (collectively, “Updates”). Updates may also modify or delete in
            their entirety certain features and functionality. You shall
            promptly download and install all Updates and you acknowledge and
            agree that our Platform or portions thereof may not properly operate
            should you fail to do so.
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            17. USER PROVIDED CONTENT
          </p>

          <p className="opacity-85">
            In the event our Platform or Services allow you to contribute
            content, including by uploading data and information, posting
            messages, sending or sharing files and communicating with others you
            agree not to post, upload, e-mail, transmit, distribute, or
            otherwise make available through our Platform or Services any data,
            text, images, files, links, software, or communications that (i) we
            consider, in our sole and absolute discretion, to violate any
            applicable laws, including without limitation the laws of the United
            States; (ii) restricts or inhibits any other user from using and
            enjoying our Platform or Services s; (iii) is threatening, abusive,
            harassing, defamatory, obscene, offensive, harmful, obnoxious,
            pornographic, profane, or indecent; (iv) contains viruses, worms,
            Trojan horses, harmful code, or any other element or component that
            has a capacity or potential to interrupt, destroy, interfere with,
            permit the unauthorized use of, or limit the functionality of any
            computer software or hardware, any telecommunications equipment, or
            any communications network; (v) violates or infringes the rights of
            others, including without limitation rights under copyright,
            trademark, or patent law; rights of privacy or publicity; rights
            under defamation law; or rights under any securities or other
            applicable law; (vi) sells or promotes any product or service; (vii)
            promotes, solicits, or partakes in any multi-level marketing or
            pyramid scheme; (viii) posts or transmits any unsolicited
            advertising, or promotional materials (spam); or (ix) provide a
            false identity or claim a relationship between you and any business,
            organization, or person for which you are not authorized or legally
            permitted to claim such a relationship.
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            18. PRIVACY
          </p>

          <p className="opacity-85">
            Any personally identifiable information in electronic communications
            to our Platform or Services is governed by the Privacy Policy.
            Winit, as owner of our Platform or Services, shall be free to use or
            copy all other information in any such communications, including any
            ideas, inventions, concepts, techniques or know-how disclosed
            therein, for any purposes. Such purposes may include disclosure to
            third parties and/or developing, manufacturing and/or marketing
            goods or services. The sender of any communications to our Platform
            or Services or otherwise to Winit shall be responsible for the
            content and information contained therein, including its
            truthfulness and accuracy.
          </p>

          <p className="opacity-85">
            IN ORDER TO PROVIDE YOU WITH OUR PLATFORM AND THE SERVICES AND ONLY
            IN ACCORDANCE WITH THE PRIVACY POLICY, WHICH IS INCORPORATED BY
            REFERENCE INTO THESE TERMS, our Platform and THE SERVICES NEED TO
            COLLECT, USE AND DISCLOSE YOUR PERSONAL INFORMATION. WINIT MAY
            MODIFY THE PRIVACY POLICY IN ITS SOLE DISCRETION AT ANY TIME AND
            SUCH MODIFICATIONS WILL BE EFFECTIVE UPON POSTING TO OUR PLATFORM.
            MOREOVER, WINIT SHALL BEAR NO LIABILITY WHATSOEVER FOR ANY LOSS,
            DAMAGE OR EXPENSE ARISING, DIRECTLY OR INDIRECTLY, FROM AMENDMENTS
            TO THE PRIVACY POLICY. EACH TIME YOU USE OUR PLATFORM, YOU CONSENT
            TO THE COLLECTION, USE AND DISCLOSURE OF YOUR PERSONAL INFORMATION
            ACCORDING WITH THE PRIVACY POLICY AS IT THEN READS. YOU UNDERSTAND
            THAT THIRD PARTIES, MAY COLLECT, USE OR DISCLOSE YOUR PERSONAL
            INFORMATION WHILE USING OUR PLATFORM AND THE SERVICES AND THAT SUCH
            COLLECTION, USE OR DISCLOSURE IS SUBJECT TO SUCH THIRD PARTIES’
            PRIVACY POLICIES.
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            19. ASSIGNMENT; NO THIRD PARTY BENEFICIARIES
          </p>

          <p className="opacity-85">
            These Terms and the Privacy Policy shall not be assignable by you,
            either in whole or in part and any attempted assignment or
            delegation will be null, void and of no effect.&nbsp; We reserve the
            right to assign all or a portion of our rights and obligations under
            these Terms and Privacy Policy, in our sole discretion. This Terms
            and the Privacy Policy shall be binding and inure to our benefit and
            each of our successors and assigns. The covenants set forth in these
            Terms are intended solely for the benefit of Winit, its successors
            and permitted assigns. Nothing herein, whether express or implied,
            will confer upon any person or entity, other than the parties, their
            successors and permitted assigns, any legal or equitable right
            whatsoever to enforce any provision of these Terms.
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            20. GOVERNING LAW; VENUE; LIMITATION OF TIME TO FILE CLAIMS
          </p>

          <p className="opacity-85">
            The validity, interpretation and enforcement of these Terms, matters
            arising therefrom or related thereto or to our Platform shall be
            governed by the internal laws of the State of New York, without
            reference to choice of law doctrine.
          </p>

          <p className="opacity-85">
            Any legal action or proceeding concerning the validity,
            interpretation and enforcement of these Terms, matters arising
            therefrom or related thereto or to our Platform, shall be brought
            exclusively in the Federal and State courts located in New York
            County, New York (of in the Southern District of New York), and the
            Parties consent to the exclusive jurisdiction of those courts,
            waiving any objection to the propriety or convenience of such
            venues. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, EACH
            PARTY HERETO VOLUNTARILY WAIVES (IN CONNECTION WITH ANY DISPUTE
            ARISING OUT OF THESE TERMS OR OUR PLATFORM), ANY RIGHT TO: (1) A
            TRIAL BY JURY IN ANY LITIGATION IN ANY COURT; AND (2) BRING CLAIMS
            AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
            REPRESENTATIVE ACTION OR CONSOLIDATE ANY ACTION IN WHICH A JURY
            TRIAL HAS BEEN WAIVED WITH ANY OTHER ACTION IN WHICH A JURY TRIAL
            CANNOT BE WAIVED.
          </p>

          <p className="opacity-85">
            ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
            TO OUR PLATFORM OR THE SERVICES MUST BE COMMENCED WITHIN ONE (1)
            YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF
            ACTION OR CLAIM IS PERMANENTLY BARRED.
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            21. MISCELLANEOUS
          </p>

          <p className="opacity-85">
            These Terms constitute the entire agreement between you and Winit
            with respect to our Platform and the Services, and supersede all
            prior or contemporaneous understandings and agreements, whether
            written or oral, with respect thereto. Your access of our Platform
            or use of the Services shall not be construed as creating a
            partnership, employment, agency, or joint venture relationship
            between Winit and you. No failure to nor delay in exercising any
            right or any power hereunder by either party shall operate as a
            waiver thereof, nor shall any single or partial exercise of any
            right or power hereunder preclude further exercise of that or any
            other right hereunder. In the event of a conflict between these
            Terms and any applicable purchase agreement or other terms, these
            Terms shall govern. If any provision of these terms is illegal or
            unenforceable under applicable law, the remainder of the provision
            will be amended to achieve as closely as possible the effect of the
            original term and all other provisions of these Terms will continue
            in full force and effect. These Terms will be binding upon, and
            inure to the benefit of the parties and their respective successors
            and assigns.
          </p>

          <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
            22. NOTICES; CONTACT US
          </p>
          <p className="opacity-85">
            Any notices or other communications permitted or required hereunder
            will be in writing and delivered by Winit to you (i) by email (to
            the address that you maintain with your Account); or (ii) by posting
            on our Platform. You are responsible to maintain your email mailbox
            and agree to check your mailbox frequently. We assume no
            responsibility for a missed communication or any consequence caused
            by such missed communication. All notices shall be deemed effective
            one (1) business day after successful transmission (or posting)
            thereof. Should you have any questions about these Terms or
            otherwise need to contact us for any reason, please{" "}
            <a href="mailto:contact@appwinit.com">email</a> us or contact us at:{" "}
            <b style={{ fontFamily: "AvenirNext bold" }}>
              1216 Broadway. 2nd Fl. New York, NY 10001
            </b>
          </p>
          <p className="opacity-85">Last Updated: February 2018</p>
        </div>
      </div>
    </div>
  );
};

export default observer(TermsComponent);
