import React from "react";
import "./spinner.css";

const Spinner = () => (
  <div className="spinner">
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </div>
);

export default Spinner;
