import React from "react";
import { Row, Col } from "reactstrap";
import { SUPPORT_EMAILS, emailLinkGenerator } from "../helpers";
import { observer } from "mobx-react";
import useStore from "../hooks/useStore";

const ContactInfoComponent = () => {
  const { general } = useStore();

  return (
    <div className="w-120 p-2">
      <Row>
        <Col>
          Email{" "}
          <a
            href={emailLinkGenerator(
              SUPPORT_EMAILS[general.location.name.toLowerCase()]
            )}
          >
            {SUPPORT_EMAILS[general.location.name.toLowerCase()]}
          </a>
        </Col>
      </Row>
    </div>
  );
};

export default observer(ContactInfoComponent);
