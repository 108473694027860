import React from "react";
import { observer } from "mobx-react";
import useStore from "../hooks/useStore";
import { Col, Row, Container } from "reactstrap";
import RenderSearchForm from "./renderSearchForm";
import {
  StatusModal,
  SearchFormSelector,
  DescribeSite,
  FeaturedIn,
  WhatWeOffer,
  Recaptcha
} from "../components";
import { CITY_TO_LANDING_LOGO_MAPPING } from "../helpers";

const Search = () => {
  const { search, general } = useStore();

  const onTokenError = error => {
    console.error("captcha error", error);
    general.setError({
      title: "Captcha Error",
      message: "Captcha enountered a network error. Please try again later."
    });
  };

  const landingPageLogo =
    CITY_TO_LANDING_LOGO_MAPPING[general.location.name.toLowerCase()];
  return (
    <Col>
      {search.fetching && (
        <StatusModal
          message="Searching for Tickets..."
          secondMessage="This may take a minute."
        />
      )}
      <Row>
        <Container>
          <Col sm={12} md lg>
            <DescribeSite location={general.location} />
          </Col>
        </Container>
      </Row>
      <Row>
        <Container>
          <Col>
            <Container>
              <Row className="pb-3">
                <SearchFormSelector
                  currentSearchType={search.searchType}
                  changeSearchType={search.setSearchType}
                />
              </Row>
              <Row>
                <RenderSearchForm />
              </Row>
              <Row className="pt-3 justify-content-end">
                <Recaptcha
                  token={search.recaptchaToken}
                  onChange={search.setRecaptchaToken}
                  onErrored={onTokenError}
                />
              </Row>
            </Container>
          </Col>
        </Container>
      </Row>
      <Row className="pt-5" style={{ overflow: "hidden" }}>
        <Container>
          <Col
            sm={12}
            md
            lg
            className="d-flex justify-content-center align-items-center"
          >
            {landingPageLogo ? (
              <img src={landingPageLogo.img} alt={landingPageLogo.alt} />
            ) : (
              ""
            )}
          </Col>
        </Container>
      </Row>
      <Row>
        <Container className="py-5">
          <p
            style={{
              fontFamily: "avenir-next-medium, system, sans-serif",
              color: "#6c6d6e"
            }}
          >
            *Winit is a privately held company which charges the fees for this
            service that are listed on the payments page. Winit is not
            affiliated, associated, authorized, endorsed by, or in any way
            officially connected with any city, county or state government
            agency.
          </p>
        </Container>
      </Row>
      <Row>
        <Container className="py-5">
          <Col>
            <WhatWeOffer />
          </Col>
        </Container>
      </Row>
      <Row className="border-bottom border-gray-line" />
      <Row>
        <Container className="py-5">
          <Col sm={12} md lg>
            <FeaturedIn />
          </Col>
        </Container>
      </Row>
    </Col>
  );
};

export default observer(Search);
