
export const escapeRegExp = str =>
  str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1'); // eslint-disable-line

export const replaceAll = (source, search, replacement = '') =>
  source.replace(new RegExp(escapeRegExp(search), 'g'), replacement);

export const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const capitalizeEveryFirstLetter = (string) => {
  const split = string.split(' ');
  return split.map(word => capitalizeEveryFirstLetter(word)).join(' ');
};

export const getWord = (string, number = 0) => {
  const splitWords = string.split(' ');
  if (number > splitWords.length) return splitWords[splitWords.length - 1];
  return splitWords[number];
};
