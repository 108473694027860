import { useState } from "react";
import { PLATE_STATES } from "../../../helpers";


const useEvervault = () => {
  const [isReady, setIsReady] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [billingAddressLine1, setBillingAddressLine1] = useState(null);
  const [billingAddressZip, setBillingAddressZip] = useState(null);
  const [billingAddressCity, setBillingAddressCity] = useState(null);
  const [billingAddressState, setBillingAddressState] = useState(PLATE_STATES.IL);
  const [billingAddressCountry, setBillingAddressCountry] = useState("United States");
  const [cardScheme, setCardScheme] = useState("");
  const [token, setToken] = useState("");

  return {
    isReady, setIsReady,
    isValid, setIsValid,
    billingAddressLine1, setBillingAddressLine1,
    billingAddressZip, setBillingAddressZip,
    billingAddressCity, setBillingAddressCity,
    billingAddressState, setBillingAddressState,
    billingAddressCountry, setBillingAddressCountry,
    token, setToken,
    cardScheme, setCardScheme
  }
}

export default useEvervault;
