import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import classNames from "classnames";

import "./violationsSelectorTable.css";

import { CheckBox } from "./index";
import moment from "moment";

const ViolationsSelectorTable = ({
  violations,
  selected,
  toggleViolation,
  filterText,
  clearFilter
}) => {
  if (violations.length === 0) {
    return (
      <div className="violations-selector-table-no-results">
        {filterText ? (
          <h5>Nothing Found For: {filterText}</h5>
        ) : (
          <h5>No Results </h5>
        )}
        {filterText ? (
          <div
            onClick={clearFilter}
            className="violations-selector-table-no-results-action"
          >
            Click Here to Clear the Filter
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
  return (
    <div className="violations-selector-table-container">
      {violations.map(violation => {
        const isPayable = violation.amount > 0;
        const className = classNames({
          "violations-selector-table-item": true,
          "violations-selector-table-item-payable": isPayable,
          "violations-selector-table-item-not-payable": !isPayable,
          "violations-selector-table-item-selected": !!selected[violation.violationNumber]
        });
        let statusColor = "";
        switch (violation.status) {
          case "warning":
            statusColor = "violations-selector-table-status-warning";
            break;
          case "notice of violation":
            statusColor = "violations-selector-table-status-due";
            break;
          case "paid":
            statusColor = "violations-selector-table-status-paid";
            break;
          case "dismissed":
            statusColor = "violations-selector-table-status-paid";
            break;
          default:
            statusColor = "violations-selector-table-status-due";
        }
        return (
          <div
            className={className}
            key={violation.violationNumber}
            onClick={() => {
              if (isPayable) toggleViolation(violation.violationNumber);
            }}
          >
            <div className="violations-selector-table-checkbox">
              <CheckBox
                checked={!!selected[violation.violationNumber]}
                disabled={!isPayable}
              />
            </div>
            <div className="flex-grow-1 pl-1">
              <Row>
                <Col
                  className="violations-selector-table-item-cell"
                  lg={{ size: 1, order: 1 }}
                  md={{ size: 2, order: 1 }}
                  sm={{ size: 4, order: 1 }}
                  xs={{ size: 5, order: 1 }}
                >
                  {typeof violation.amount === "number" &&
                  violation.amount !== 0
                    ? `$${violation.amount.toFixed(2)}`
                    : "$0.00"}
                </Col>
                <Col
                  className="violations-selector-table-item-cell"
                  lg={{ size: 3, order: 1 }}
                  md={{ size: 8, order: 2 }}
                  sm={{ size: 12, order: 3 }}
                  xs={{ size: 10, order: 4 }}
                >
                  {typeof violation.description === "string" &&
                    violation.description.toUpperCase()}
                </Col>
                <Col
                  className="violations-selector-table-item-cell"
                  lg={{ size: 2, order: 1 }}
                  md={{ size: 3, order: 1 }}
                  sm={{ size: 4, order: 1 }}
                  xs={{ size: 5, order: 1 }}
                >
                  {violation.violationNumber}
                </Col>
                <Col
                  className="violations-selector-table-item-cell"
                  lg={{ size: 2, order: 1 }}
                  md={{ size: 3, order: 1 }}
                  sm={{ size: 4, order: 1 }}
                  xs={{ size: 5, order: 3 }}
                >
                  {violation.licensePlate}
                </Col>
                <Col
                  className="violations-selector-table-item-cell"
                  lg={{ size: 2, order: 1 }}
                  md={{ size: 4, order: 1 }}
                  sm={{ size: 4, order: 4 }}
                  xs={{ size: 5, order: 2 }}
                >
                   {moment(violation.issueDate).format("L")}
                </Col>
                <Col
                  lg={{ size: 2, order: 1 }}
                  md={{ size: 4, order: 2 }}
                  sm={{ size: 8, order: 4 }}
                  xs={{ size: 10, order: 5 }}
                  className={classNames(
                    statusColor,
                    "violations-selector-table-item-cell"
                  )}
                >
                  {typeof violation.status === "string" &&
                    violation.status.toUpperCase()}
                </Col>
              </Row>
            </div>
          </div>
        );
      })}
    </div>
  );
};

ViolationsSelectorTable.defaultProps = {
  violations: [],
  selected: {},
  toggleViolation: () => {},
  clearFilter: () => {},
  filterText: ""
};

ViolationsSelectorTable.propTypes = {
  violations: PropTypes.array,
  selected: PropTypes.object,
  toggleViolation: PropTypes.func,
  clearFilter: PropTypes.func,
  filterText: PropTypes.string
};

export default ViolationsSelectorTable;
