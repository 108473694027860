import React, { useEffect } from "react";
import { Container } from "reactstrap";
import { Header, Footer } from "./components";
import { RenderModals } from "./containers";
import { observer } from "mobx-react";
import useStore from "./hooks/useStore";

const TemplateComponent = props => {
  const { general } = useStore();

  useEffect(() => {
    window.addEventListener("resize", general.updateWidth);
    return () => {
      window.removeEventListener("resize", general.updateWidth);
    };
  }, [general.updateWidth]);

  return (
    <Container fluid className="d-flex flex-column p-0 m-0 h-100">
      <Header
        location={general.location}
        isMobile={general.isMobile}
        toggle={general.toggleMenu}
        toggleServices={() => {
          general.toggleModalVisibility("showServices");
        }}
        toggleContact={() => {
          general.toggleModalVisibility("showContact");
        }}
        showMobileMenu={general.showMobileMenu}
        goHome={general.goHome}
      />
      <RenderModals />
      <div className="flex-grow-1">{props.children}</div>
      <Footer
        toggleTerms={() => {
          general.toggleModalVisibility("showTerms");
        }}
        togglePrivacy={() => {
          general.toggleModalVisibility("showPrivacyPolicy");
        }}
      />
    </Container>
  );
};

export default observer(TemplateComponent);
