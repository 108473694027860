import io from "socket.io-client";
import TRACKING from "../tracking/google";
import mobxStore from "../stores/AppStore";

let timer = {
  start: () => {},
  finish: () => {}
};
export const resetTimer = () => {
  timer = TRACKING.action.searchTimer();
  timer.start();
};

const socket = io(process.env.REACT_APP_BACKEND_URL);

socket.on("connect", () => {});

socket.on("searchTickets", result => {
  timer.finish();
  mobxStore.search.setRecaptchaToken(null);
  mobxStore.violation.receiveFees(result.fees);
  mobxStore.violation.receiveViolations(result.violations);
  return mobxStore.violation.selectAllValidViolations();
});
socket.on("searchTicketsError", error => {
  console.error("searchTicketsError ", error);
  mobxStore.search.setFetchingStatus(false);
  timer.finish();
  mobxStore.search.setRecaptchaToken(null);
  let message = "We could not perform the search at this time.";
  if (error && error.message && typeof error.message === "string") {
    message = error.message;
  }
  return mobxStore.general.setError({
    title: "Something went wrong.",
    message: message
  });
});

export default socket;
