import React, { useEffect } from "react";
import { observer } from "mobx-react";
import useStore from "../../hooks/useStore";
import Select from "react-select";
import { COUNTRIES } from "../../helpers";

const BillingAddressCountry = () => {
  const { payment } = useStore();

  useEffect(() => {
    payment.setPaymentField("billingAddressCountry", "United States");
  }, []);

  const handleChange = event => {
    payment.setPaymentField("billingAddressCountry", event.target.value);
    payment.setPaymentField("billingAddressState", "");
  };

  const countrySelectOptions = COUNTRIES.map(country => {
    return {
      value: country.country,
      label: country.country
    };
  });

  return (
    <Select
      value={
        payment.billingAddressCountry.value
          ? {
              value: payment.billingAddressCountry.value,
              label: payment.billingAddressCountry.value
            }
          : null
      }
      placeholder="Country"
      onChange={e => {
        const event = {
          target: {
            name: "plateState",
            value: e.value
          }
        };
        return handleChange(event);
      }}
      isSearchable
      options={countrySelectOptions}
    />
  );
};

export default observer(BillingAddressCountry);
