import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const PageModal = ({ size, toggle, title, children }) => (
  <Modal size={size} fade centered isOpen toggle={toggle}>
    {title && <ModalHeader toggle={toggle}>{title}</ModalHeader>}
    <ModalBody>{children}</ModalBody>
  </Modal>
);

PageModal.defaultProps = {
  size: "lg",
  title: ""
};

PageModal.propTypes = {
  title: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  size: PropTypes.oneOf(["lg", "sm"])
};

export default PageModal;
