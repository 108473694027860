import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./history";
import GoogleAnalytics from "./tracking/google";
import GoogleTagManager from "./tracking/googleTagManager";

import mobxStore from "./stores/AppStore";
import { Provider as MobxProvider } from "mobx-react";

import Template from "./template";
import Rendersteps from "./containers/renderSteps";
import Unsubscribe from "./containers/unsubscribe";

// for google analytics
GoogleAnalytics.initialize();

// for google Tag Manager
GoogleTagManager.initialize();

const App = () => (
  <MobxProvider store={mobxStore}>
    <Router history={history}>
      <Template>
        <Switch>
          <Route path="/unsubscribe" component={Unsubscribe} />
          <Route path="*" component={Rendersteps} />
        </Switch>
      </Template>
    </Router>
  </MobxProvider>
);

export default App;
