import React from "react";
import { Row, Col } from "reactstrap";

import "./violationsSelectorTable.css";

const ViolationsSelectorTableHeader = () => (
  <div className="violations-selector-table-item violations-selector-table-item-header">
    <div className="violations-selector-table-checkbox" />
    <div className="flex-grow-1 pl-1">
      <Row>
        <Col
          className="violations-selector-table-item-cell"
          lg={{ size: 1, order: 1 }}
          md={{ size: 2, order: 1 }}
          sm={{ size: 4, order: 1 }}
          xs={{ size: 5, order: 1 }}
        >
          AMOUNT
        </Col>
        <Col
          className="violations-selector-table-item-cell"
          lg={{ size: 3, order: 1 }}
          md={{ size: 8, order: 2 }}
          sm={{ size: 12, order: 3 }}
          xs={{ size: 10, order: 4 }}
        >
          VIOLATION
        </Col>
        <Col
          className="violations-selector-table-item-cell"
          lg={{ size: 2, order: 1 }}
          md={{ size: 3, order: 1 }}
          sm={{ size: 4, order: 1 }}
          xs={{ size: 5, order: 1 }}
        >
          SUMMONS #{" "}
        </Col>
        <Col
          className="violations-selector-table-item-cell"
          lg={{ size: 2, order: 1 }}
          md={{ size: 3, order: 1 }}
          sm={{ size: 4, order: 1 }}
          xs={{ size: 5, order: 3 }}
        >
          PLATE{" "}
        </Col>
        <Col
          className="violations-selector-table-item-cell"
          lg={{ size: 2, order: 1 }}
          md={{ size: 4, order: 1 }}
          sm={{ size: 4, order: 4 }}
          xs={{ size: 5, order: 2 }}
        >
          DATE
        </Col>
        <Col
          lg={{ size: 2, order: 1 }}
          md={{ size: 4, order: 2 }}
          sm={{ size: 8, order: 4 }}
          xs={{ size: 10, order: 5 }}
          className="violations-selector-table-item-cell"
        >
          STATUS
        </Col>
      </Row>
    </div>
  </div>
);

export default ViolationsSelectorTableHeader;
