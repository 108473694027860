import ReactGTM from "react-gtm-module";

/**
 * Must be called prior to any other calls for user tracking.
 */
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
}

let initialize = () => { };
if (process.env.REACT_APP_GTM_ID) {
  initialize = () => ReactGTM.initialize(tagManagerArgs);
}

export default { initialize }
