import { SUPPORT_EMAILS } from "../helpers";

/**
 * Generates a href to email support
 * @param {string} subject Optional value to prepopulate subject field.
 * @param {string} subject Optional value to prepopulate body field.
 * Returns a string formatted for href context.
 */
const emailLinkGenerator = (email = SUPPORT_EMAILS['chicago'], subject = "", body = "") => {
  let baseHref = `mailto:${email}`;
  if (subject) baseHref += `?subject=${subject}`;
  if (body) baseHref += `?body=${body}`;
  return baseHref;
};

export default emailLinkGenerator;
