import ReactGA from "react-ga";

// import getSearchTypeNumValue from "./getSearchTypeNumValue";

/**
 * Must be called prior to any other calls for user tracking.
 */
let initialize = () => { };
if (process.env.REACT_APP_GA_ID) {
  initialize = () => ReactGA.initialize(process.env.REACT_APP_GA_ID);
}

/**
 * Tracks user exceptions on the site.
 * @param {string} name The name of the exception user performed.
 */
const exceptionTracker = name => {
  if (process.env.REACT_APP_GA_ID) return ReactGA.exception(name);
  return false;
};

/**
 * Tracks user actions on the site.
 * @param {string} name The name of the action user performed.
 * @param {number} value Optional value for the action
 * @param {string} label Optional label for the action
 */
const eventTracker = (name, value, label) => {
  const obj = {
    category: "Actions",
    action: name
  };
  if (value) obj.value = value;
  if (label) obj.label = label;
  if (process.env.REACT_APP_GA_ID) return ReactGA.event(obj);
  return false;
};

const timingBuilder = ({
  name, category, label, onFinish, onStart,
}) => {
  let startTime;
  let finishTime;
  const startFunc = () => {
    try {
      startTime = new Date().getTime();
      if (onStart && typeof onStart === 'function') onStart();
    } catch (error) {
      console.error('error [startFunc]', name, ' + ', error);
    }
  };
  const finishFunc = () => {
    try {
      finishTime = new Date().getTime();
      const value = finishTime - startTime;
      ReactGA.timing({
        category,
        variable: name,
        value, // in milliseconds
        label,
      });
      if (onFinish && typeof onFinish === 'function') onFinish();
    } catch (error) {
      console.error('error [finishFunc]', name, ' + ', error);
    }
  };
  return {
    start: startFunc,
    finish: finishFunc,
  };
};

/**
 * Tracks user STEPS on the site.
 * @param {string} name The name of the page viewed.
 * @param {string} label Optional label for the action
 */
const reachedStep = (stepname, label) => {
  const obj = {
    category: "Navigation",
    action: stepname
  };
  if (label) obj.label = label;
  if (process.env.REACT_APP_GA_ID) return ReactGA.event(obj);
  return false;
};

/**
 * Tracks user page views on the site.
 * @param {string} label label for the page
 */
const viewPage = label => {
  if (process.env.REACT_APP_GA_ID) return ReactGA.pageview(`/${label}`);
  return false;
};

/**
 * Tracks user modal views on the site.
 * @param {string} label label for the modal
 */
const viewModal = label => {
  if (process.env.REACT_APP_GA_ID) return ReactGA.modalview(`/${label}`);
  return false;
};

const view = {
  /**
   * Tracks user viewing home page.
   */
  home: () => {
    viewPage("Home");
    reachedStep("Viewed Home Page");
  },
  /**
   * Tracks user viewing searched tickets page.
   */
  ticketsList: () => {
    viewPage("Tickets");
    reachedStep("Viewed Tickets");
  },
  /**
   * Tracks user viewing payment page.
   */
  paymentForm: () => {
    viewPage("Payments");
    reachedStep("Viewed Payment Page");
  },
  /**
   * Tracks user viewing order successfully placed page.
   */
  success: () => {
    viewPage("OrderComplete");
    reachedStep("Viewed Order Placed");
  },
  /**
   * Tracks user viewing services modal.
   */
  services: () => {
    viewModal("Services");
    reachedStep("Viewed Services Modal");
  },
  /**
   * Tracks user viewing services modal.
   */
  contact: () => {
    viewModal("Contact");
    reachedStep("Viewed Contact Modal");
  },
  /**
   * Tracks user viewing terms and conditions modal.
   */
  terms: () => {
    viewModal("Terms");
    reachedStep("Viewed Terms & Conditions Modal");
  },
  /**
   * Tracks user viewing services modal.
   */
  privacy: () => {
    viewModal("Privacy");
    reachedStep("Viewed Privacy Policy Modal");
  }
};

const action = {
  /**
   * Tracks user search validation messages
   * @param {string} message The message displayed to the user
   */
  cookiesDisabled: () =>
    eventTracker("Disabled Cookies", null, "User with disabled cookies"),
  /**
   * Tracks user search validation messages
   * @param {string} message The message displayed to the user
   */
  searchValidation: message =>
    eventTracker("Missing Required Detail", null, message),
  /**
   * Tracks user clicking to search for tickets.
   * @param {string} searchType The type of ticket search a user is performing.
   */
  searchTickets: searchType =>
    // const searchTypeNumValue = getSearchTypeNumValue(searchType);
    eventTracker("Searched for Tickets", null, searchType),
  /**
   * Tracks user selecting a ticket.
   * @param {number} ticketAmount The fine amount user checked.
   * @param {string} searchType The current search type used.
   */
  selectTicket: (ticketAmount, searchType) =>
    eventTracker(
      "Selected A Ticket For Payment (Ticket Amount)",
      ticketAmount,
      searchType
    ),
  /**
   * Tracks user selecting a ticket.
   * @param {number} ticketAmount The fine amount user checked.
   * @param {string} searchType The current search type used.
   */
  deselectTicket: (ticketAmount, searchType) =>
    eventTracker(
      "Deselected Ticket For Payment (Ticket Amount)",
      ticketAmount,
      searchType
    ),
  /**
   * Tracks clicking checkout button.
   * @param {number} totalFines The total fine amount user wants to checkout with.
   * @param {string} searchType The current search type used.
   */
  clickCheckout: (totalFines, searchType) =>
    eventTracker("Clicked Checkout (Total Fines)", totalFines, searchType),
  /**
   * Tracks order submission with total.
   * @param {number} totalAmount The total order amount.
   * @param {string} searchType The current search type used.
   */
  submitPayment: (totalAmount, searchType) =>
    eventTracker("Submitted Payment (Order Total)", totalAmount, searchType),
  /**
   * Tracks order submission errors
   * @param {string} message The message the user saw.
   */
  submitPaymentError: message =>
    exceptionTracker(`Submit Payment Error - ${message}`),
  searchTimer: () => timingBuilder({
    name: 'Search Time',
    category: 'Search',
    label: 'Search Time',
  }),
};

export default {
  initialize,
  view,
  action
};
