import React, { createRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./searchBox.css";
import ICON from "../../assets/svg/magnifyingGlass.svg";

const SearchBoxComponent = ({
  className,
  disabled,
  handleSubmit,
  handleChange,
  fieldName,
  placeHolder,
  value
}) => {
  const inputRef = createRef();
  return (
    <form
      onSubmit={event => {
        if (!disabled) handleSubmit(event);
      }}
      className={classnames(className, {
        "search-box-default": !className,
        "search-box-disabled": disabled
      })}
    >
      <div
        onClick={() => {
          if (inputRef && inputRef.current) {
            inputRef.current.focus();
          }
        }}
      >
        <img src={ICON} alt="search icon" />
      </div>
      <input
        disabled={disabled}
        ref={inputRef}
        value={value}
        placeholder={placeHolder}
        name={fieldName}
        id={fieldName}
        onChange={event => {
          if (!disabled) handleChange(event.target.value);
        }}
        type="text"
      />
    </form>
  );
};

SearchBoxComponent.defaultProps = {
  placeHolder: "Search",
  fieldName: "search",
  className: "",
  value: "",
  disabled: false,
  handleChange: () => {},
  handleSubmit: event => {
    event.preventDefault();
  }
};

SearchBoxComponent.propTypes = {
  value: PropTypes.string,
  placeHolder: PropTypes.string,
  fieldName: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func
};

export default SearchBoxComponent;
