import moment from "moment";

const filterViolations = (filterText, violations) =>
  new Promise((resolve, reject) => {
    try {
      const results = violations.filter(violation => {
        const longDate = moment(violation.issueDate)
          .format("LLLL")
          .toLowerCase();
        const shortDate = moment(violation.issueDate)
          .format("L")
          .toLowerCase();
        return (
          violation.violationNumber.toString().indexOf(filterText) >= 0 ||
          longDate.indexOf(filterText) >= 0 ||
          shortDate.indexOf(filterText) >= 0 ||
          violation.description.toLowerCase().indexOf(filterText) >= 0 ||
          violation.licensePlate.toLowerCase().indexOf(filterText) >= 0 ||
          violation.status.toLowerCase().indexOf(filterText) >= 0
        );
      });
      resolve(results);
    } catch (error) {
      reject(error);
    }
  });

export default filterViolations;
