import Cookies from 'cookies-js';

const checkCookiesDisabled = () => new Promise((resolve) => {
  let { cookieEnabled } = navigator;
  const name = '__pct-challenge';
  const temporaryKey = new Date().getTime() + '';
  Cookies.set(name, temporaryKey);
  setTimeout(() => {
    const found = Cookies.get(name) || '';
    if (found.trim() === temporaryKey.trim()) {
      cookieEnabled = true;
    } else {
      cookieEnabled = false;
    }
    Cookies.set(name, '');
    Cookies.expire(name);
    resolve(!cookieEnabled);
  }, 200);
});

export default checkCookiesDisabled;
