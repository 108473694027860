import React from "react";
import PropTypes from "prop-types";
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
  FormText,
  Fade
} from "reactstrap";

const FullInputComponent = ({
  showLabel,
  value,
  dirty,
  error,
  name,
  handleChange,
  handleBlur,
  placeholder,
  title,
  disabled,
  type,
  className,
  notRequired
}) => {
  let isNotValid
  let invalidMessage;
  if (notRequired) {
    isNotValid = error.length > 0;
    invalidMessage = error
  } else {
    isNotValid = (dirty && value.length === 0) || error.length > 0;
    invalidMessage = error || `${title} is a required field.`;
  }
  return (
    <FormGroup>
      {showLabel && <Label for={name}>{title}</Label>}
      <Input
        invalid={isNotValid}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        disabled={disabled}
        className={className}
      />

      {isNotValid ? (
        <Fade in={isNotValid} tag={FormFeedback}>
          {invalidMessage}
        </Fade>
      ) : (
        <FormText />
      )}
    </FormGroup>
  );
};

FullInputComponent.defaultProps = {
  showLabel: false,
  value: "",
  dirty: false,
  error: "",
  name: "",
  handleChange: () => {},
  handleBlur: () => {},
  placeholder: "",
  title: "",
  disabled: false,
  type: "input",
  className: ""
};

FullInputComponent.propTypes = {
  showLabel: PropTypes.bool,
  value: PropTypes.string,
  dirty: PropTypes.bool,
  error: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  className: PropTypes.string
};

export default FullInputComponent;
