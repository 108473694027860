// eslint-disable-next-line
const calculateCreditCardFees = (totalSelectedFines, creditCardFeePercentage) => {
  if (totalSelectedFines * creditCardFeePercentage < 1)
    return 1
  else
    return totalSelectedFines * creditCardFeePercentage
}


const calculateTotalFines = selectFines =>
  Object.keys(selectFines).reduce(
    (total, id) => total + selectFines[id].amount,
    0
  );

export { calculateCreditCardFees, calculateTotalFines };
