import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";

import { Button } from "./index";

import "./violationsToolBarBottom.css";

import GoogleAnalytics from "../tracking/google";

const ViolationsToolBarBottom = ({
  selectedViolations,
  totalFines,
  goToPayment,
  searchType
}) => (
  <Row className="pt-2">
    <Col
      lg={{ size: 3, order: 1 }}
      md={{ size: 6, order: 2 }}
      sm={{ size: 12, order: 2 }}
      className="d-flex flex-column justify-content-start pt-2"
    >
      <div className="violations-toolbar-bottom-fine-amount-header">
        Your Fine Amount ({selectedViolations.length} Ticket
        {selectedViolations.length > 1 || selectedViolations.length === 0
          ? "s"
          : ""}
        )
      </div>
      <div className="violations-toolbar-bottom-fine-amount">
        ${totalFines.toFixed(2)}
      </div>
    </Col>
    <Col
      lg={{ size: 6, order: 2 }}
      md={{ size: 0, order: 1 }}
      sm={{ size: 0, order: 1 }}
    />
    <Col
      lg={{ size: 3, order: 3 }}
      md={{ size: 6, order: 3 }}
      sm={{ size: 12, order: 3 }}
      className="d-flex justify-content-end pt-2"
    >
      <Button
        shadow
        onClick={() => {
          GoogleAnalytics.action.clickCheckout(totalFines, searchType);
          return goToPayment();
        }}
        disabled={!selectedViolations.length}
      >
        {selectedViolations.length === 0
          ? "Select A Ticket To Continue"
          : `Continue with ${selectedViolations.length}
        ${selectedViolations.length > 1 ? "Tickets" : "Ticket"}`}
      </Button>
    </Col>
  </Row>
);

ViolationsToolBarBottom.defaultProps = {
  selectedViolations: [],
  totalFines: 0,
  goToPayment: () => {},
  searchType: "fullTicket"
};

ViolationsToolBarBottom.propTypes = {
  selectedViolations: PropTypes.array,
  totalFines: PropTypes.number,
  goToPayment: PropTypes.func,
  searchType: PropTypes.string
};

export default ViolationsToolBarBottom;
