import React from "react";
import useStore from "../../../hooks/useStore";
import { Col, Fade, Row } from "reactstrap";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";

import "./CkoDropin.css";
import BillingAddressLine1 from "../billingAddressLine1";
import BillingAddressLine2 from "../billingAddressLine2";
import BillingAddressZip from "../billingAddressZip";
import BillingAddressCity from "../billingAddressCity";
import BillingAddressState from "../billingAddressState";
import BillingAddressCountry from "../billingAddressCountry";
import { observer } from "mobx-react";

const CheckoutDropin = () => {
  const { payment } = useStore();

  const handleCardTokenized = async event => {
    await payment.submitOrder(event.token, event.scheme);
  };

  const handleCardTokenizationFailed = event => {
    console.error("Card tokenization failed.", event);
  };

  const handleReady = () => {
    payment.paymentReadyStatusChange(true);
  };

  const handleCardValidationChanged = () => {
    payment.cardValidityStatusChange(Frames.isCardValid());
  };

  return (
    <div className="checkout-dropin-wrapper">
      <Fade in={payment.paymentReady} className="checkout-dropin-fade">
        <Frames
          config={{
            debug: process.env.NODE_ENV === "production" ? true : false,
            publicKey: process.env.REACT_APP_CKO_PUBLIC_KEY,
            localization: {
              cardNumberPlaceholder: "Card number",
              expiryMonthPlaceholder: "MM",
              expiryYearPlaceholder: "YY",
              cvvPlaceholder: "CVV"
            },
            style: {
              // https://www.checkout.com/docs/integrate/frames/frames-customization-guide
              base: {
                fontSize: "16px",
                border: "1px solid #ced4da",
                borderRadius: "4px",
                paddingLeft: "10px",
                color: "#495057"
              }
            },
            cardholder: {
              billingAddress: {
                addressLine1: payment.BillingAddressLine1,
                addressLine2: payment.BillingAddressLine2,
                zip: payment.BillingAddressZip,
                city: payment.BillingAddressCity,
                state: payment.BillingAddressState,
                country: payment.BillingAddressCountry
              }
            }
          }}
          // https://www.checkout.com/docs/integrate/frames/frames-reference
          ready={handleReady}
          cardTokenized={handleCardTokenized}
          cardTokenizationFailed={handleCardTokenizationFailed}
          cardValidationChanged={handleCardValidationChanged}
        >
          <Row>
            <Col>
              <CardNumber style={{ height: "40px" }} />
            </Col>
          </Row>
          <div className="cko-dropin-field-spacer" />
          <Row>
            <Col>
              <ExpiryDate style={{ height: "40px" }} />
            </Col>
            <Col>
              <Cvv style={{ height: "40px" }} />
            </Col>
          </Row>
          <div className="cko-dropin-field-spacer" />
          {/* CKO/frames-react doesn't implement fields for billing address: */}
          <Row>
            <Col>
              <BillingAddressLine1 />
            </Col>
          </Row>
          <Row>
            <Col>
              <BillingAddressLine2 />
            </Col>
          </Row>
          <Row>
            <Col>
              <BillingAddressCity />
            </Col>
            <Col>
              <BillingAddressState />
            </Col>
          </Row>
          <Row>
            <Col>
              <BillingAddressZip />
            </Col>
            <Col>
              <BillingAddressCountry />
            </Col>
          </Row>
        </Frames>
      </Fade>
    </div>
  );
};

export default observer(CheckoutDropin);
