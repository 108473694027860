import { CITY } from './constants'

const getCity = () => {
  if (CITY) return CITY // We want to use the CITY variable first
  let city = getCityFromURLParameters() // if CITY is not SET, we want to use url params to set the city (for easier local dev)
  if (city) {
    localStorage.city = city
    return city
  }
  city = localStorage.city // if no CITY or no params, let's check the local storage
  if (city) return city
  return 'chicago'
}

// This function will maybe be used in the future.

// const getCityByDomainName = () => {
//   const currentURL = window.location.hostname
//   const domain = DOMAIN_TO_CITY_MAPPING[currentURL]
//   if (domain) return domain.city
//   return undefined
// }

const getCityFromURLParameters = () => {
  let params = new URL(window.location.href).searchParams
  return params.get('city')
}

export default getCity
