import visitorInfo from "visitor-info";

const getVisitorInfo = () => {
  const safeVisitorInfo = visitorInfo();
  try {
    if (safeVisitorInfo && safeVisitorInfo.timezone) {
      safeVisitorInfo.timezone = safeVisitorInfo.timezone.name || "UNKNOWN";
    }
    // if (safeVisitorInfo && safeVisitorInfo.timezone && safeVisitorInfo.timezone.abbrs) {
    //   delete safeVisitorInfo.timezone.abbrs;
    // }
    // if (safeVisitorInfo && safeVisitorInfo.timezone && safeVisitorInfo.timezone.untils) {
    //   delete safeVisitorInfo.timezone.untils;
    // }
    // if (safeVisitorInfo && safeVisitorInfo.timezone && safeVisitorInfo.timezone.offsets) {
    //   delete safeVisitorInfo.timezone.offsets;
    // }
    // if (safeVisitorInfo && safeVisitorInfo.timezone && safeVisitorInfo.timezone.population) {
    //   delete safeVisitorInfo.timezone.population;
    // }
    if (safeVisitorInfo && safeVisitorInfo.country) {
      safeVisitorInfo.country = safeVisitorInfo.country.name || safeVisitorInfo.country.alpha3 || "UNKNOWN";
    }
    // if (safeVisitorInfo && safeVisitorInfo.country && safeVisitorInfo.country.alpha2) {
    //   delete safeVisitorInfo.country.alpha2;
    // }
    // if (safeVisitorInfo && safeVisitorInfo.country && safeVisitorInfo.country.countryCallingCodes) {
    //   delete safeVisitorInfo.country.countryCallingCodes;
    // }
    // if (safeVisitorInfo && safeVisitorInfo.country && safeVisitorInfo.country.currencies) {
    //   delete safeVisitorInfo.country.currencies;
    // }
    // if (safeVisitorInfo && safeVisitorInfo.country && safeVisitorInfo.country.emoji) {
    //   delete safeVisitorInfo.country.emoji;
    // }
    // if (safeVisitorInfo && safeVisitorInfo.country && safeVisitorInfo.country.ioc) {
    //   delete safeVisitorInfo.country.ioc;
    // }
    // if (safeVisitorInfo && safeVisitorInfo.country && safeVisitorInfo.country.status) {
    //   delete safeVisitorInfo.country.status;
    // }
  } catch (err) {
    console.error('error cleaning info', err);
  }
  return safeVisitorInfo;
};

export default getVisitorInfo;
