import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";

import "./violationsToolBarTop.css";

import { SearchBox, SearchDescription, CheckBox } from "./index";

const ViolationsToolBarTop = ({
  selectAll,
  restartSearch,
  violations,
  clearSelected,
  search,
  selectedViolations,
  handleFilterChange
}) => (
  <Fragment>
    <Row className="pb-3">
      <Col
        xs={{ size: 12 }}
        sm={{ size: 12 }}
        md={{ size: 6 }}
        lg={{ size: 8 }}
        className="d-flex flex-column justify-content-center "
      >
        <SearchDescription search={search} violations={violations} />
        <h4>
          {violations.items.length}{" "}
          {violations.items.length > 1 ? "Violations" : "Violation"}
        </h4>
      </Col>
      <Col
        xs={{ size: 12 }}
        sm={{ size: 12 }}
        md={{ size: 6 }}
        lg={{ size: 4 }}
        className="d-flex align-items-center justify-content-center"
      >
        <SearchBox
          value={violations.filterText}
          handleChange={handleFilterChange}
          placeHolder="Filter tickets..."
        />
      </Col>
    </Row>
    <Row className="pb-3 violations-toolbar-top-border-bottom">
      <Col>
        <Row>
          <Col className="d-inline-flex flex-row align-items-center">
            <span className="violations-toolbar-top-action" onClick={selectAll}>
              <div className="d-inline-flex align-items-center">
                <CheckBox checked={violations.allSelected} />{" "}
                <span className="violations-toolbar-top-check-action">
                  Select All
                </span>
              </div>
            </span>
            <span
              className="violations-toolbar-top-seperator violations-toolbar-top-action"
              onClick={restartSearch}
            >
              New Search
            </span>
            {violations.items.length >= 1 &&
              selectedViolations.length >= 1 && (
                <Fragment>
                  <span
                    className="violations-toolbar-top-seperator violations-toolbar-top-action"
                    onClick={clearSelected}
                  >
                    Clear Selection
                  </span>
                </Fragment>
              )}
          </Col>
        </Row>
      </Col>
    </Row>
  </Fragment>
);

ViolationsToolBarTop.propTypes = {
  restartSearch: PropTypes.func.isRequired,
  violations: PropTypes.object.isRequired,
  selectedViolations: PropTypes.array.isRequired,
  clearSelected: PropTypes.func.isRequired,
  selectAll: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  handleFilterChange: PropTypes.func.isRequired
};

export default ViolationsToolBarTop;
