import React from "react";

const PrivacyComponent = () => (
  <div>
    <div className="row pl-4 pr-4 pb-3">
      <div className="col-xs-12">
        <p className="opacity-85">
          We here at Winit, LLC (together with our affiliates, “Winit”, “us”,
          “our” or “we”) would like to thank you for your interest in our
          website and mobile applications and our related Services (as defined
          below) (collectively, our “Platform”). This Privacy Policy (this
          “Policy”) explains how information about you is collected, used and
          disclosed by Winit when you use our Platform and the related online
          products and services (collectively, the “Services”) or when you
          otherwise interact with us. All capitalized terms which aren’t defined
          in this Policy will have the meaning given to them in our{" "}
          <a href="https://www.appwinit.com/terms-of-use">Terms of Use.</a>
        </p>

        <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
          If you do not accept this Policy, you are not authorized to use our
          Platform. We reserve the right to modify this Policy at any time and
          such modification will be effective upon posting to our Platform
          (whether on our website or via our mobile applications) and/or
          provision of email notice to you thereof. Your continued use of our
          Platform and/or Services following any such posting or notification of
          such revised Policy shall be deemed to constitute your acceptance of
          any such modifications. Please review this Policy periodically for
          changes and pay attention to the “Last Updated” date displayed at the
          bottom of this page. By accepting this Policy, you agree to let Winit
          collect, use and disclose information about you in accordance with the
          purposes listed below.
        </p>

        <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
          1. COLLECTION OF INFORMATION
        </p>

        <p className="opacity-85">
          <i>Information You Provide to Us</i>
        </p>

        <p className="opacity-85">
          We collect information you provide directly to us, such as when you
          register with our Mobile App, send us a new Ticket, request customer
          support, send us an email or otherwise interact with us. The types of
          information we may collect about you include your name, social
          networking screen names or IDs, email address and any other
          information you choose to provide.
        </p>

        <p className="opacity-85">
          <i>Information We Collect Automatically Through Our Services</i>
        </p>

        <p className="opacity-85">
          When you access or use our Platform, we may automatically collect
          information about you, including:
        </p>

        <p className="opacity-85">
          <u>Logged Information</u>: We may log information about your use of
          our Platform, including the type of browser you use, access times,
          pages viewed, your IP address, and the page you visited before
          navigating to our Platform.
        </p>

        <p className="opacity-85">
          <u>Device Information</u>: We may collect information about the device
          you use to access our Platform, including information about the
          device’s software and hardware, Media Access Control address and other
          unique device identifiers, device token, mobile network information
          and time zone.
        </p>

        <p className="opacity-85">
          <u>
            Information Collected by Cookies and Other Tracking Technologies
          </u>
          : We may use various technologies to collect information, and this may
          include sending cookies to your computer or mobile device. Cookies are
          small data files stored on your computer’s hard drive or in device
          memory that help us to improve our Platform and your experience and
          see which areas and features of our Platform are popular. We may also
          collect information using web beacons (also known as “tracking
          pixels”). Web beacons are electronic images that may be used in our
          Platform or emails and help deliver cookies, count visits, and
          understand usage and campaign effectiveness. For more information
          about cookies, and how to disable them, please see “Your Choices”
          below.
        </p>

        <p className="opacity-85">
          <i>Information We Collect From Other Sources</i>
        </p>

        <p className="opacity-85">
          We may also obtain information from other sources and combine that
          with information we collect through our Services. For example, if you
          log into our Services using a third party site or platform such as
          Facebook, we may access information about you from that site or
          platform, such as your screen name, profile information and friend
          lists, in accordance with the authorization procedures determined by
          such third party site or platform. We may also collect information
          from app stores when you download or update one of our applications.
        </p>

        <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
          2. USE OF INFORMATION
        </p>

        <p className="opacity-85">
          We may use information about you for various purposes, including to:
        </p>

        <ul className="opacity-85">
          <li>Provide, maintain and improve our current Platform.</li>
          <li>Develop new Services or features on our Platform.</li>
          <li>
            Provide and deliver the products and services you request and send
            you related information.
          </li>
          <li>
            Respond to your comments, questions and requests and provide
            customer service and support.
          </li>
          <li>
            Send you technical notices, updates, security alerts and support and
            administrative messages.
          </li>
          <li>
            Communicate about products, services, offers, promotions and events
            offered by Winit and others.
          </li>
          <li>
            Provide news and information we think will be of interest to you.
          </li>
          <li>
            Personalize and improve our Platform and Services and provide
            tailored advertisements, content or features.
          </li>
          <li>
            Monitor and analyze trends, usage and activities in connection with
            our Platform.
          </li>
          <li>
            To fulfill the purpose for which you provide it. For example, if you
            give us an e-mail address to use the “e-mail a friend” feature of
            our Website or App, we will transmit the contents of that e-mail and
            your e-mail address to the recipients.
          </li>
          <li>
            To comply with any court order, law or legal process (including
            responding to government or regulatory requests).
          </li>
          <li>
            To enforce our rights arising from any contracts entered into
            between you and us, including the Application EULA, and for billing
            and collection purposes.
          </li>
          <li>
            If we believe disclosure is necessary or appropriate to protect the
            rights, property, or safety of Winit, our customers or others. This
            includes exchanging information with other companies and
            organizations for the purposes of fraud protection and credit risk
            reduction.
          </li>
          <li>
            Link or combine with information we get from others to help
            understand your needs and provide you with better service; and Carry
            out any other purpose for which the information was collected.
          </li>
          <li>
            For any other purpose (i) disclosed by us when you provide the
            information; and/or (ii) with your consent.
          </li>
        </ul>

        <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
          3. SHARING OF INFORMATION
        </p>

        <p className="opacity-85">
          We may share information about you as follows or as otherwise
          described in this Privacy Policy:
        </p>

        <ul className="opacity-85">
          <li>
            With vendors, consultants and other service providers who need
            access to such information to carry out work on our behalf.
          </li>
          <li>
            In response to a request for information if we believe disclosure is
            in accordance with any applicable law, regulation or legal process,
            or as otherwise required by any applicable law, rule or regulation;
          </li>
          <li>
            If we believe your actions are inconsistent with the spirit or
            language of our user agreements or policies (including without
            limitation the Terms and this Policy).
          </li>
          <li>
            To protect the rights, property and safety of Winit or others.
          </li>
          <li>
            In connection with, or during negotiations of, any merger, sale of
            company assets, financing or acquisition of all or a portion of our
            business to another company.
          </li>
          <li>With your consent or at your direction.</li>
        </ul>

        <p className="opacity-85">
          Please note that we may share aggregated or de-identified information,
          which cannot reasonably be used to identify you, in our sole
          discretion.
        </p>

        <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
          4. SOCIAL SHARING FEATURES
        </p>

        <p className="opacity-85">
          Our Platform may offer social sharing features and other integrated
          tools (such as the Facebook “Like” button), which let you share
          actions you take on our Platform with other media, and vice versa. The
          use of such features enables the sharing of information with your
          friends or the public, depending on the settings you establish with
          the entity that provides the social sharing feature. For more
          information about the purpose and scope of data collection and
          processing in connection with social sharing features, please visit
          the privacy policies of those third party entities that provide these
          features to you.
        </p>

        <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
          5. ADVERTISING AND ANALYTICS SERVICES PROVIDED BY OTHERS
        </p>

        <p className="opacity-85">
          We may allow third parties to serve advertisements and provide
          analytics services in connection with our Platform. These entities may
          use cookies, web beacons and other tracing technologies to collect
          information about your use of our Platform, including your IP address,
          Media Access Control address, device identifiers, software and
          hardware information, browser information, time zone and usage
          information. This information may be used by Winit and others to,
          among other things, analyze and track data, determine the popularity
          of certain content, deliver advertising and content targeted to your
          interests and better understand your activity on our Platform.
        </p>

        <p className="opacity-85">
          Third-party ad serving and analytics technologies are integrated into
          our Platform, so if you do not want to be subject to these
          technologies, do not use or access our Platform.
        </p>

        <p className="opacity-85">
          Our Platform may also integrate third party Application Program
          Interfaces (APIs), which allow others to collect and process
          information about you, including your geo-location information, to
          provide you with tailored advertisements, offers and other content.
          You have the ability to control the collection and use of your
          geo-location information at all times. We will not allow third parties
          to access this information without your opt-in consent, and you may
          disable location-based services at any time by adjusting the settings
          of your mobile device.
        </p>

        <p className="opacity-85">
          We do not control third parties’ collection or use of your information
          to serve interest-based advertising. However these third parties may
          provide you with ways to choose not to have your information collected
          or used in this way. You can opt out of receiving targeted ads from
          members of the Network Advertising Initiative (“NAI”) on the NAI’s
          website.
        </p>

        <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
          6. SECURITY
        </p>

        <p className="opacity-85">
          Winit takes reasonable measures to help protect information about you
          from loss, theft, misuse and unauthorized access, disclosure,
          alteration and destruction.
        </p>

        <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
          7. YOUR CHOICES
        </p>

        <p className="opacity-85">
          <i>Account Information:</i> You may request that we update, correct or
          delete information about you at any time by{" "}
          <a href="mailto:contact@appwinit.com">emailing us</a>, but note that
          we may retain certain information as required by law or for legitimate
          business purposes. We may also retain cached or archived copies of
          information about you for a certain period.
        </p>

        <p className="opacity-85">
          <i>Cookies:</i> Most device browsers are set to accept cookies by
          default. If you prefer, you can usually choose to set your device
          browser to remove or reject browser cookies. Please note that if you
          choose to remove or reject cookies, this could affect the availability
          and functionality of certain features of our Platform
        </p>

        <p className="opacity-85">
          <i>Push Notifications:</i> With your consent, we may send push
          notifications or alerts to your mobile device to provide game-related
          information, service updates, promotional communications and other
          related messages. You can deactivate these notifications by changing
          your notification settings on your device.
        </p>

        <p className="opacity-85">
          <i>Promotional Communications:</i> You may opt out of receiving
          promotional communications from Winit by following the instructions in
          those communications or by{" "}
          <a href="mailto:contact@appwinit.com">emailing us</a>. If you opt out,
          we may still send you non-promotional communications, such as those
          about our ongoing business relations.
        </p>

        <p className="opacity-85">
          <i>Storage of Information:</i> Winit will store your information so
          long as it is necessary in order to provide you with our Platform and
          in compliance with all applicable laws.
        </p>

        <p className="opacity-85" style={{ fontFamily: "AvenirNext bold" }}>
          8. CONTACT US
        </p>

        <p className="opacity-85">
          Should you have any questions about this Policy or otherwise need to
          contact us for any reason, please{" "}
          <a href="mailto:contact@appwinit.com">email us</a> or contact us at:{" "}
          <b style={{ fontFamily: "AvenirNext bold" }}>
            Winit LLC, 1216 Broadway. 2nd Fl. New York, NY 10001
          </b>
        </p>

        <p className="opacity-85">Last Updated: January 2018</p>
      </div>
    </div>
  </div>
);
export default PrivacyComponent;
