import React from "react";
import moment from "moment";
import { Row, Col } from "reactstrap";

const OrderTimeoutWarning = props => (
  <div className="w-100 p-1">
    <Row>
      <Col>
        <h1 className="text-danger">Heads Up!</h1>
        <p>
          Your order must be completed by{" "}
          <b>{moment(props.time).format("h:mm a")}</b>.
        </p>
        <p>
          You have{" "}
          <span className="text-danger">
            {moment(props.time).diff(moment(), "minutes")} minutes
          </span>{" "}
          to submit your order.
        </p>
      </Col>
    </Row>
  </div>
);

export default OrderTimeoutWarning;
