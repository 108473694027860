import React from "react";
// import { Row, Col } from "reactstrap";
import SectionTitle from "./sectionTitle";

import "./featuredIn.css";

import NYT from "../assets/png/nyt.png";
import ABC7 from "../assets/png/abc7.png";
import NYP from "../assets/png/nyp.png";
import BLOOMBERG from "../assets/png/bloomberg.png";
import PIX11 from "../assets/png/pix11.png";

const FeaturedIn = () => (
  <div className="pt-5 pb-5 d-flex flex-column justify-content-center align-items-center">
    <SectionTitle active>THEY TALK ABOUT US</SectionTitle>
    <h2 className="pb-5">Featured in</h2>
    <div className="featured-in-main">
      <a
        className="featured-in-site"
        href="http://www.nytimes.com/2015/07/19/nyregion/an-app-to-help-you-win-the-parking-ticket-war.html?_r=0"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="featured-in-img" src={NYT} alt="New York Times" />
      </a>
      <a
        className="featured-in-site"
        href="http://abc7ny.com/technology/have-a-parking-ticket-theres-an-app-to-help-you-fight-it/792844/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="featured-in-img" src={ABC7} alt="ABC 7 News" />
      </a>
      <a
        className="featured-in-site"
        href="http://nypost.com/2015/06/14/this-app-will-fight-your-parking-ticket-so-you-dont-have-to/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="featured-in-img" src={NYP} alt="New York Post" />
      </a>
      <a
        className="featured-in-site"
        href="http://www.bloomberg.com/news/articles/2015-12-03/apps-that-fight-your-parking-tickets"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="featured-in-img"
          src={BLOOMBERG}
          alt="Bloomberg Businessweek"
        />
      </a>
      <a
        className="featured-in-site"
        href="http://pix11.com/2015/06/15/new-app-helps-you-get-nyc-parking-tickets-dismissed/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="featured-in-img" src={PIX11} alt="PIX 11" />
      </a>
    </div>
  </div>
);

export default FeaturedIn;
