import React from "react";
import { Row, Col } from "reactstrap";

const CookiesDisabled = () => (
  <div className="w-100 p-1">
    <Row>
      <Col>
        <h2>Sorry, our website requires cookies.</h2>
        <p>
          It looks like you have cookies disabled on your browser.
          To protect our users and provide a seamless user experience we use cookies.
        </p>

        <h4>Please enable cookies and refresh the page.</h4>

        <p>
          If you are unsure how to enable cookies please check <a
            href="https://www.wikihow.com/Enable-Cookies-in-Your-Internet-Web-Browser"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>.
        </p>
      </Col>
    </Row>
  </div>
);

export default CookiesDisabled;
