import React from "react";
import { observer } from "mobx-react";
import { Container, Row, Col } from "reactstrap";
import { STEPS, objectToArray } from "../helpers";
import {
  ViolationsSelectorTable,
  ViolationsSelectorTableHeader,
  ViolationsToolBarTop,
  ViolationsToolBarBottom
} from "../components";
import useStore from "../hooks/useStore";

const Violations = () => {
  const { violation: violations, search, general } = useStore();

  const violationsArray = violations.filterText
    ? violations.filterResults
    : violations.items;
  const selectedViolationsArray = objectToArray(violations.selected);
  return (
    <Container>
      <Row>
        <Col>
          <ViolationsToolBarTop
            violations={violations}
            restartSearch={general.resetEverything}
            clearSelected={violations.resetSelectedViolations}
            search={search}
            searchType={search.searchType}
            selectedViolations={selectedViolationsArray}
            selectAll={violations.toggleSelectAll}
            handleFilterChange={violations.handleViolationsFilterChange}
          />
          <ViolationsSelectorTableHeader />
          <ViolationsSelectorTable
            selected={violations.selected}
            violations={violationsArray}
            toggleViolation={violations.addOrRemoveViolation}
            clearFilter={() => {
              violations.handleViolationsFilterChange("");
            }}
            filterText={violations.filterText}
          />
          <ViolationsToolBarBottom
            selectedViolations={selectedViolationsArray}
            totalFines={violations.totalSelectedFines}
            goToPayment={() => {
              general.setStep(STEPS.payment);
            }}
            searchType={search.searchType}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default observer(Violations);
