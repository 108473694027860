import React from "react";
import "./searchDescription.css";
import { SEARCH_TYPES, PLATE_STATES } from "../helpers";

const SearchDescriptionString = props => {
  const { search } = props;
  switch (search.searchType) {
    case SEARCH_TYPES.full:
      return (
        <div className="search-description">
          {search.lastName} {search.plateNumber}
          {` `}
          {PLATE_STATES[search.plateState]}
        </div>
      );
    case SEARCH_TYPES.ticket:
      return <div className="search-description">{search.ticketId}</div>;
    default:
      return <div />;
  }
};
export default SearchDescriptionString;
