import { getRoot, types } from "mobx-state-tree";
import moment from "moment";
import {
  STEPS,
  CITIES,
  ORDER_TIME_LIMIT,
  WARNING_TIME,
  visitorInfo,
  checkCookiesDisabled,
  getCity
} from "../helpers";
import GoogleAnalytics from "../tracking/google";

const MOBILE_MENU_WIDTH = 800;

const city = getCity();

const GeneralStore = types
  .model("GeneralStore", {
    location: types.frozen(CITIES[city]), // city to search and pay in
    error: types.maybeNull(types.frozen({})), // holds an error to be displayed
    step: STEPS.search, // Current step in funnel
    width: window.innerWidth,
    isMobile: window.innerWidth <= MOBILE_MENU_WIDTH,
    showMenu: !(window.innerWidth <= MOBILE_MENU_WIDTH),
    showCookiesDisabled: false,
    showContact: false, // boolean for showing contact form
    showServices: false, // boolean for showing services modal
    showTerms: false,
    showPrivacyPolicy: false,
    orderTimeoutWarning: types.maybeNull(types.string),
    orderTimeoutWarningToggle: false, // display warning order will timeout soon
    orderTimeout: types.maybeNull(types.string), // holds timeout
    orderTimeoutTime: 0, // time left before order end
    visitorInfo: types.frozen(visitorInfo())
  })
  .actions(self => {
    return {
      loadGeneralState(state) {
        for (let key of state) {
          self[key] = state[key];
        }
      },

      setWidth(width) {
        self.width = width;
        self.isMobile = width <= MOBILE_MENU_WIDTH;
        self.showMenu = !(window.innerWidth <= MOBILE_MENU_WIDTH);
      },

      getWindowWidth() {
        // eslint-disable-next-line
        const width = window.innerWidth;
        return self.setWidth(width);
      },

      resetGeneral() {
        self.location = CITIES[city];
        self.error = null;
        self.step = STEPS.search;
        self.width = window.innerWidth;
        self.isMobile = window.innerWidth <= MOBILE_MENU_WIDTH;
        self.showMenu = !(window.innerWidth <= MOBILE_MENU_WIDTH);
        self.showCookiesDisabled = false;
        self.showContact = false;
        self.showServices = false;
        self.showTerms = false;
        self.showPrivacyPolicy = false;
        self.orderTimeoutWarning = null;
        self.orderTimeoutWarningToggle = false;
        self.orderTimeout = null;
        self.orderTimeoutTime = 0;
        self.visitorInfo = visitorInfo();
      },

      resetEverything() {
        clearTimeout(self.orderTimeout);
        clearTimeout(self.orderTimeoutWarning);
        const { search, violation, payment } = getRoot(self);
        search.resetSearch();
        violation.resetViolations();
        payment.resetPayment();
        return self.resetGeneral();
      },

      setOrderTimeoutTime(time) {
        self.orderTimeoutTime = time;
      },

      setOrderTimeoutWarningToggle() {
        self.orderTimeoutWarningToggle = !self.orderTimeoutWarningToggle;
      },

      setOrderTimeout(timeoutHolder, warningHolder) {
        self.orderTimeout = timeoutHolder;
        self.orderTimeoutWarning = warningHolder;
      },

      startOrderTimeout() {
        const now = moment();
        const timeoutTime = now.add(ORDER_TIME_LIMIT, "ms");
        self.setOrderTimeoutTime(timeoutTime);
        const timeout = setTimeout(() => {
          self.resetEverything();
        }, ORDER_TIME_LIMIT);
        const warning = setTimeout(() => {
          self.setOrderTimeoutWarningToggle();
        }, WARNING_TIME);
        return self.setOrderTimeout(timeout, warning);
      },

      setShowCookiesDisabled(value) {
        self.showCookiesDisabled = value;
      },

      cookieChecker() {
        checkCookiesDisabled().then(showCookiesDisabled => {
          if (showCookiesDisabled) {
            GoogleAnalytics.action.cookiesDisabled();
          }
          self.setShowCookiesDisabled(showCookiesDisabled);
        });
      },

      toggleModalVisibility(modal) {
        if (self[modal] === false) {
          // means we are about to show it
          switch (modal) {
            case "showContact":
              GoogleAnalytics.view.contact();
              break;
            case "showServices":
              GoogleAnalytics.view.services();
              break;
            case "showTerms":
              GoogleAnalytics.view.terms();
              break;
            case "showPrivacyPolicy":
              GoogleAnalytics.view.privacy();
              break;
            default:
              break;
          }
        }
        self[modal] = !self[modal];
      },

      toggleMobileMenu() {
        self.showMenu = !self.showMenu;
      },

      setStep(step) {
        self.step = step;
      },

      setError(error) {
        self.error = error;
      },

      clearError() {
        self.error = null;
      }
    };
  });

export default GeneralStore;
