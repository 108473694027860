const pushMicrosoftConversion = (actionName) => {
  try {
    if (!process.browser || !window || !window.uetq) return false
    return window.uetq.push('event', actionName, {})
  } catch (error) {
    console.error('Issue with Microsoft conversion: ', error)
    return false
  }
}

const microsoftConversions = {
  complete: () => pushMicrosoftConversion('chicago_parking_ticket_payment'),
}

export default microsoftConversions
