import React from "react"
import PropTypes from "prop-types"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'

import "./checkoutSummary.css"

const CheckoutSummaryComponent = ({
                                    editViolations,
                                    paidViolations,
                                    unpaidViolations,
                                    violationsTotal,
                                    serviceFee,
                                    creditCardFee,
                                    text,
                                    total,
                                    totalAdditionalTicketFee,
                                    plateNumber,
                                    isFinalSummary
                                  }) => (
  <div className="checkout-summary-container">
    <h4>Summary</h4>
    {text && <p className="checkout-summary-secured">{text}</p>}

    <div className="checkout-summary-list">
      {paidViolations && paidViolations.map(violation => (
        <div
          key={violation.violationNumber}
          className="checkout-summary-list-item checkout-summary-violations-item"
        >
          <div className="checkout-summary-paid-violation">
            <FontAwesomeIcon icon={faCheckCircle} color="green"/>
            <span className="checkout-summary-violation-number"> # {violation.violationNumber}</span>
          </div>
          <div className="checkout-summary-item-amount-paid">
            ${violation.amount.toFixed(2)}
          </div>
        </div>
      ))}
      {unpaidViolations && unpaidViolations.map(violation => (
        <div
          key={violation.violationNumber}
          className="checkout-summary-list-item checkout-summary-violations-item"
        >
          <div className="checkout-summary-unpaid-violation">
            <FontAwesomeIcon icon={faExclamationTriangle} color="red"/>
            <span className="checkout-summary-violation-number"># {violation.violationNumber}</span>
          </div>
          <div className="checkout-summary-item-amount-unpaid">
            ${violation.amount.toFixed(2)}
          </div>
        </div>
      ))}
      {typeof editViolations === "function" && (
        <div className="checkout-summary-list-action" onClick={editViolations}>
          Add or Remove Violations
        </div>
      )}
    </div>
    <div className="checkout-summary-totals-list">
      <div className="checkout-summary-list-item">
        <div>{isFinalSummary ? 'CITY CHICAGO TICKET' : 'Tickets'}</div>
        <div className="checkout-summary-item-amount">
          ${violationsTotal.toFixed(2)}
        </div>
      </div>
      <div className="checkout-summary-list-item">
        <div>{isFinalSummary ? 'CITYCHGO* SERVICEFEES' : 'Chicago Credit Card Fee'}</div>
        <div className="checkout-summary-item-amount">
          ${creditCardFee.toFixed(2)}
        </div>
      </div>
      <div className="checkout-summary-list-item">
        <div>
          {isFinalSummary ? `CHI*TICKET ${plateNumber} WINIT` : 'Processing Fee'}
        </div>
        <div className="checkout-summary-item-amount">
          {serviceFee === 0 ?
            <b>waived</b> : `$${(serviceFee + totalAdditionalTicketFee).toFixed(2)}`}
        </div>
      </div>
    </div>
    <div className="checkout-summary-list checkout-summary-grand-total">
      <div className="checkout-summary-list-item">
        <div>Total</div>
        <div className="checkout-summary-item-amount">${total.toFixed(2)}</div>
      </div>
    </div>
  </div>
)

CheckoutSummaryComponent.defaultProps = {
  text: "",
  editViolations: false
}

CheckoutSummaryComponent.propTypes = {
  text: PropTypes.string,
  paidViolations: PropTypes.array.isRequired,
  unpaidViolations: PropTypes.array,
  violationsTotal: PropTypes.number.isRequired,
  creditCardFee: PropTypes.number.isRequired,
  serviceFee: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  editViolations: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  plateNumber: PropTypes.string,
  isFinalSummary: PropTypes.bool
}

export default CheckoutSummaryComponent
