import React, { Fragment } from "react";
import useStore from "../hooks/useStore";
import { observer } from "mobx-react";
import {
  ErrorAlert,
  PageModal,
  WhatWeOffer,
  ContactInfo,
  Terms,
  Privacy,
  OrderTimeoutWarning,
  CookiesDisabled
} from "../components";

const RenderModals = () => {
  const { general } = useStore();
  const {
    error,
    showContact,
    showServices,
    showCookiesDisabled,
    showTerms,
    showPrivacyPolicy,
    showTimeoutWarning,
    orderTimeoutTime
  } = general;

  return (
    <Fragment>
      {error && Object.keys(error).length && (
        <ErrorAlert errorToggle={general.clearError} error={error} />
      )}
      {showContact && (
        <PageModal
          size="lg"
          toggle={() => {
            general.toggleModalVisibility("showContact");
          }}
          title="Contact Us"
        >
          <ContactInfo />
        </PageModal>
      )}
      {showCookiesDisabled && (
        <PageModal
          hideToggle
          size="lg"
          toggle={() => {}}
          title="Cookies Required"
        >
          <CookiesDisabled />
        </PageModal>
      )}
      {showServices && (
        <PageModal
          toggle={() => {
            general.toggleModalVisibility("showServices");
          }}
          title="Our Services"
        >
          <WhatWeOffer mini />
        </PageModal>
      )}
      {showTerms && (
        <PageModal
          toggle={() => {
            general.toggleModalVisibility("showTerms");
          }}
          title="Terms of Service"
        >
          <Terms />
        </PageModal>
      )}
      {showPrivacyPolicy && (
        <PageModal
          toggle={() => {
            general.toggleModalVisibility("showPrivacyPolicy");
          }}
          title="Privacy Policy"
        >
          <Privacy />
        </PageModal>
      )}
      {showTimeoutWarning && (
        <PageModal
          size="sm"
          toggle={general.setOrderTimeoutWarningToggle}
          title="Warning!"
        >
          <OrderTimeoutWarning time={orderTimeoutTime} />
        </PageModal>
      )}
    </Fragment>
  );
};

export default observer(RenderModals);
