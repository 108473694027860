export default function register() {
  console.info("sw");
  unregister();
}

export function unregister() {
  console.info("r-sw");

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
