import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./checkBox.css";

const CheckBoxComponent = ({
  checked,
  onClick,
  className,
  checkColor,
  checkWidth,
  disabled,
  width,
  height
}) => (
  <svg
    className={classnames(className, {
      "checkbox-default": !className,
      "checkbox-default-disabled": disabled === true
    })}
    onClick={() => {
      if (!disabled) onClick();
    }}
    viewBox="0 0 24 24"
    style={{ width, height }}
  >
    <path
      fill="none"
      stroke={checkColor}
      strokeWidth={checkWidth}
      d={checked ? "M1.73,12.91 8.1,19.28 22.79,4.59" : ""}
    />
  </svg>
);

CheckBoxComponent.defaultProps = {
  checkWidth: 4,
  className: "",
  checkColor: "#3089F3",
  onClick: () => {},
  disabled: false,
  width: 25,
  height: 25
};

CheckBoxComponent.propTypes = {
  checkWidth: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  checkColor: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

export default CheckBoxComponent;
