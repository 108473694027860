import { types, flow, getRoot } from "mobx-state-tree";
import axios from "axios";
import {
  calculateCreditCardFees,
  STEPS,
  calculateTotalFines,
  filterViolations,
  BACKEND_URL,
  visitorInfo
} from "../helpers";
import { socketFetchTickets } from "../sockets/emitters";
import GoogleAnalytics from "../tracking/google";

const ViolationStore = types
  .model("ViolationStore", {
    items: types.array(types.frozen({})),
    filterText: "",
    filterResults: types.array(types.frozen({})),
    allSelected: false,
    selected: types.frozen({}),
    totalSelectedFines: 0, // Total fine amount of selected tickets
    creditCardFeeAmount: 0, // calculated by multiplying
    orderTotal: 0, // total amount to be charged
    serviceFeeObject: types.frozen({}),
    serviceFeeAmount: 0, // Flat amount to be added to total
    creditCardFeePercentage: 0, // to be multiplied as percentage of fines?
    additionalFeePerTicket: 0,
    totalAdditionalTicketFee: 0
  })
  .actions(self => {
    return {
      loadViolationsState(state) {
        for (let key of state) {
          self[key] = state[key];
        }
      },

      resetViolations() {
        self.items = [];
        self.filterText = "";
        self.filterResults = [];
        self.allSelected = false;
        self.selected = {};
        self.totalSelectedFines = 0;
        self.creditCardFeeAmount = 0;
        self.orderTotal = 0;
        self.serviceFeeObject = {};
        self.serviceFeeAmount = 0;
        self.creditCardFeePercentage = 0;
        self.additionalFeePerTicket = 0;
        self.totalAdditionalTicketFee = 0;
      },

      receiveFees(feesObject) {
        self.setCreditCardFeePercentage(feesObject.creditCard)
        self.setServiceFeeObject(feesObject.service)
        self.setServiceFeeAmount(feesObject.service)
        return self.setAdditionalTicketFee(feesObject.additionalFeePerTicket)
      },

      receiveViolations(violations) {
        const { general, search } = getRoot(self);
        search.setFetchingStatus(false);
        general.setStep(STEPS.result);
        return self.setViolations(violations);
      },

      requestViolations() {
        const { general, search } = getRoot(self);
        const location = general.location;
        switch (search.searchType) {
          case "ticketSearch":
            socketFetchTickets(
              { ticketId: search.ticketId, location },
              search.recaptchaToken
            );
            break;
          case "fullSearch":
            socketFetchTickets(
              {
                lastName: search.lastName,
                plateNumber: search.plateNumber,
                plateState: search.plateState,
                location
              },
              search.recaptchaToken
            );
            break;
          default:
            break;
        }
      },

      checkIfAllSelected() {
        const selectedIds = new Set(Object.keys(self.selected));
        let pointer = 0;
        let allValidSelected = true;
        while (allValidSelected && pointer < self.items.length) {
          const violation = self.items[pointer];
          if (violation.amount !== 0) {
            // check if is valid violation
            if (!selectedIds.has(violation.violationNumber))
              allValidSelected = false;
          }
          pointer += 1;
        }
        return self.setAllSelected(allValidSelected);
      },

      addOrRemoveViolation(violationId) {
        self.selectViolation(violationId);
        self.checkIfAllSelected();
        self.setTotalAdditionalTicketFee();

        const creditCardFeeAmount = calculateCreditCardFees(
          self.totalSelectedFines,
          self.creditCardFeePercentage
        );
        self.setServiceFeeAmount(self.serviceFeeObject, self.totalSelectedFines >= process.env.REACT_APP_CHICAGO_SERVICE_FEE_THRESHOLD)
        self.setCreditCardFeeAmount(creditCardFeeAmount);
        const total =
          self.serviceFeeAmount +
          creditCardFeeAmount +
          self.totalAdditionalTicketFee +
          self.totalSelectedFines;
        return self.setOrderTotal(total);
      },

      resetSelectedViolations() {
        self.clearSelectedViolations();
        return self.setOrderTotal(0);
      },

      selectAllValidViolations() {
        const selectedIds = Object.keys(self.selected);
        self.items.forEach(violation => {
          if (
            violation.amount !== 0 &&
            selectedIds.indexOf(violation.violationNumber) === -1
          ) {
            self.addOrRemoveViolation(violation.violationNumber);
          }
        });
        return self.setAllSelected(true);
      },

      toggleSelectAll() {
        if (self.allSelected) {
          return self.clearSelectedViolations();
        }
        return self.selectAllValidViolations();
      },

      handleViolationsFilterChange(filterText) {
        const cleanFilterText = filterText.trim();
        self.setViolationsFilterText(cleanFilterText);
        if (cleanFilterText === "") return self.setViolationsFilterResults([]);
        const cleanCaseFilterText = cleanFilterText.toLowerCase();
        const violations = self.items;
        return filterViolations(cleanCaseFilterText, violations)
          .then(results => self.setViolationsFilterResults(results))
          .catch(error => console.error("error filtering results", error));
      },

      getTicketsByPlates: flow(function*(plates) {
        let violations = [];
        let fees = {};

        let results;
        try {
          results = yield axios.post(
            `${BACKEND_URL}/search/chicago/me/unpaidTickets`,
            { plates }
          );
        } catch (error) {
          console.error(
            "Error getting tickets from /me/unpaid_tickets: ",
            error
          );
          return false;
        }

        if (results && results.data?.violations && results.data?.fees) {
          violations = results.data.violations; // Emulating the existing socket response from the backend
          fees = results.data.fees;
        }

        self.receiveFees(fees);
        self.receiveViolations(violations);
        self.selectAllValidViolations();

        return true;
      }),

      savePaymentDetails: flow(function*(ckoPaymentId) {
        const { general, payment } = getRoot(self);

        try {
          yield axios.post(`${BACKEND_URL}/savePaymentDetails`, {
            ckoPaymentId: ckoPaymentId,
            visitorInfo: visitorInfo(),
            uploadedImageID: payment.files.id.id ? [payment.files.id.id] : null,
            city: general.location
          });
        } catch (error) {
          console.error("Error while saving payment details: ", error);
          return false;
        }

        return true;
      }),

      setViolationsFilterResults(resultsArr) {
        self.filterResults = resultsArr;
      },

      setViolationsFilterText(text) {
        self.filterText = text;
      },

      setCreditCardFeeAmount(feeAmount) {
        self.creditCardFeeAmount = feeAmount;
      },

      setCreditCardFeePercentage(creditCardFeePercentage) {
        self.creditCardFeePercentage = creditCardFeePercentage;
      },

      setServiceFeeObject(serviceFeeObject) {
        self.serviceFeeObject = serviceFeeObject
      },

      setServiceFeeAmount(serviceFeeAmount, isHigherFee = false) {
        if (isHigherFee)
          self.serviceFeeAmount = serviceFeeAmount.higherFee
        else
          self.serviceFeeAmount = serviceFeeAmount.lowerFee
      },

      setAdditionalTicketFee(additionalFeePerTicket) {
        self.additionalFeePerTicket = parseFloat(additionalFeePerTicket);
      },

      setTotalAdditionalTicketFee() {
        const selectedCount = Object.keys(self.selected).length;
        self.totalAdditionalTicketFee =
          (selectedCount - 1) * self.additionalFeePerTicket;
      },

      setAllSelected(allSelected) {
        self.allSelected = allSelected;
      },

      setOrderTotal(total) {
        self.orderTotal = total;
      },

      clearSelectedViolations() {
        self.selected = {};
        self.allSelected = false;
      },

      setViolations(violations) {
        self.items = [
          ...violations.sort(
            (a, b) => new Date(b.issueDate) - new Date(a.issueDate)
          )
        ];
      },

      selectViolation(violationId, amount) {
        const newSelected = { ...self.selected };

        if (newSelected[violationId]) {
          // If violation was already selected, then we need to remove it.
          delete newSelected[violationId];
          GoogleAnalytics.action.deselectTicket(amount);
        } else {
          // We need to find find the violation and place it in selected object.
          let index = 0;
          let found = false; // short circuit while loop
          while (index < self.items.length && !found) {
            if (self.items[index].violationNumber === violationId) {
              found = true;
            } else {
              index += 1;
            }
          }
          newSelected[violationId] = self.items[index];
          GoogleAnalytics.action.selectTicket(amount);
        }
        // Calculate a total amount for the violations
        const newTotal = calculateTotalFines(newSelected);
        self.selected = newSelected;
        self.totalSelectedFines = newTotal;
      }
    };
  });

export default ViolationStore;
