import axios from "axios";
import { BACKEND_URL } from "../helpers";

export const savePersistedApplicationState = async (state, paymentId) => {
  let result;

  try {
    result = await axios.post(`${BACKEND_URL}/persistedState`, {
      state: JSON.stringify(state),
      paymentId
    });
  } catch (error) {
    console.error(error);
    return false;
  }

  if (result.status === 200) {
    return true;
  } else {
    return false;
  }
};

export const getPersistedApplicationState = async sessionId => {
  let result;

  try {
    result = await axios.get(`${BACKEND_URL}/persistedState/${sessionId}`);
  } catch (error) {
    console.error(error);
    return false;
  }

  if (result.status === 200) {
    return JSON.parse(result.data.state);
  } else {
    return false;
  }
};
