// location: a 'react-router-dom' location object (see useLocation() hook)
// Returns an object w/ key-value pairs representing what was found in the route query
// Returns false if no query params were found.

// Example: '/search?foo=bar&bin=baz' => {foo: bar, bin: baz}

const getRouteQuery = (location) => {
  const rawQueryString = location.search;

  if (rawQueryString) {
    const queryString = rawQueryString.slice(1); // Ignore the '?'
    const queryParams = queryString.split('&'); // Split up query parameters

    const query = {};
    queryParams.forEach((queryParam) => {
      const [key, value] = queryParam.split('=');
      query[key] = value;
    });

    return query;
  }

  return false;
};

export default getRouteQuery;
