import React from 'react'
import { Col, Row, Container } from 'reactstrap'

const Unsubscribe = () => (
  <Col>
    <Row>
      <Container>
        <Col sm={12} md lg>
          <div className="pt-5 pb-5">
            <h2>You&apos;ve unsuscribed successfully</h2>
            <div className="describe-site-offering">
              This email will no longer recieve email updates
               about newly discovered traffic tickets.
            </div>
          </div>
        </Col>
      </Container>
    </Row>
  </Col>
)

export default Unsubscribe
