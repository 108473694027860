import React, { useEffect, useState } from "react"
import { Modal, ModalBody } from "reactstrap"

const EVProcessingModal = (props) => {
  const [elapsedTime, setElapsedTime] = useState(0) // State for elapsed time

  useEffect(() => {
    if (props.isChicagoBotRunning) {
      const startTime = new Date()
      const timerInterval = setInterval(() => {
        const elapsedTime = Math.floor((new Date() - startTime) / 1000)
        setElapsedTime(elapsedTime)
      }, 1000)

      return () => clearInterval(timerInterval)
    }
  }, [props.isChicagoBotRunning]) // Dependency array to run the effect only when isChicagoBotRunning changes

  return (
    <>
      <Modal fade centered isOpen>
        <ModalBody className="p5 mx-auto">
          <img
            width="100"
            height="100"
            src="https://img.icons8.com/ios/100/0C82EA/card-in-use.png"
            alt="card-in-use"
          />
          <div className="d-flex flex-column justify-content-center align-items-center">
            <p
              style={{
                fontSize: "24px",
                fontWeight: "600",
                color: "#0C82EA",
                marginTop: 0,
                textAlign: "center",
              }}
            >
              Safely processing your payment for Chicago - Please keep this
              window open! This may take up to 180 seconds.
            </p>
            {props.isChicagoBotRunning && (
              <p
                style={{
                  color: "#FBAF32",
                  fontSize: "22px",
                  fontWeight: "600",
                }}
              >
                Elapsed Time: {elapsedTime} seconds
              </p>
            )}
            <div style={{ textAlign: "center" }}>
              <p
                style={{
                  fontSize: "15px",
                  fontStyle: "italic",
                  color: "#0C82EA",
                }}
              >
                Your ticket payment and card fee are being processed by the
                Chicago Department of Finance. WinIt is an independent entity
                and is not affiliated with the city of Chicago. If you encounter
                any problems or the page takes longer than expected please
                contact us at: help@paychicagotickets.com or call our phone
                support line at 888-839-4648.
              </p>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default EVProcessingModal
