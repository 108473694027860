import React from "react";
import { observer } from "mobx-react";
import useStore from "../../hooks/useStore";
import Select from "react-select";
import { COUNTRIES } from "../../helpers";

const BillingAddressState = () => {
  const { payment } = useStore();

  const handleChange = event => {
    payment.setPaymentField("billingAddressState", event.target.value);
  };

  const selectedCountry = COUNTRIES.find(
    countryObj => countryObj.country === payment.billingAddressCountry.value
  );

  const stateSelectOptions = selectedCountry?.states.map(state => {
    return {
      value: state,
      label: state
    };
  });

  return (
    <Select
      value={
        payment.billingAddressState.value
          ? {
              value: payment.billingAddressState.value,
              label: payment.billingAddressState.value
            }
          : null
      }
      placeholder="State"
      onChange={e => {
        const event = {
          target: {
            name: "plateState",
            value: e.value
          }
        };
        return handleChange(event);
      }}
      isSearchable
      options={stateSelectOptions}
    />
  );
};

export default observer(BillingAddressState);
