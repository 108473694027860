const parseInputError = (inputError) => {
  let concatenatedErrors = ""
  for (const { error, ticketId } of inputError) {
    if (error.match(/booted or towed|blocked|amount to be charged is more than \$1 greater than shown on Chicago DOF/)) {
      return JSON.stringify(error)
    }

    if (error.match(/not found|already paid|not payable/)) concatenatedErrors += `${ticketId}: ${error}`
  }

  return concatenatedErrors
}

const getEvervaultErrorData = (inputError) => {
  if (typeof inputError === "string")
    return { title: "Something went wrong when trying to pay your ticket to the city of Chicago", message: inputError }

  const errorMessage = parseInputError(inputError)

  if (!errorMessage) return ''

  if (errorMessage.includes("blocked")) {
    return { title: "We were unable to pay your ticket!", message: `A message from the city of Chicago: ${errorMessage}` }
  }

  if (errorMessage.includes("booted or towed")) {
    const parsedErrorMessage = errorMessage
      .replace(/^"NOTE:/, "A message from NYC DOF:")
      .replace(/See these general instructions on recovering a booted or towed vehicle\."$/, "")

    return { title: "We were unable to pay your ticket!", message: parsedErrorMessage }
  }

  if (errorMessage === "not found") {
    return { title: "We were unable to find your ticket!", message: "" }
  }

  if (errorMessage.includes("already paid") && errorMessage.includes("not found")) {
    return { title: "Multiple errors occurred", message: errorMessage }
  }

  if (errorMessage.includes("not payable")) {
    return { title: "Ticket not payable!", message: errorMessage }
  }

  return {
    title: "Service unavailable",
    message: "Something went wrong when trying to pay your ticket to the city of Chicago. \
              Please rest assured that we are actively addressing the issue and \
              once the connectivity is restored, we will promptly proceed with the payment \
              to the city of Chicago and inform you"
  }
}

export default getEvervaultErrorData;
