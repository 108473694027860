import React, { createRef, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";

const Recaptcha = ({ onChange, token, onErrored }) => {
  const captchaRef = createRef();
  const [lastTokenValue, setLastTokenValue] = useState(token);

  useEffect(() => {
    // reset the captcha if we no longer have a token
    const reset = () => {
      if(captchaRef && captchaRef.current && process.env.REACT_APP_RECAPTCHA_SITE_ID) {
        captchaRef.current.reset();
      }
    }
    // if token changes reset
    if (token !== lastTokenValue) {
      setLastTokenValue(token);
      if (!token) reset();
    }
  }, [token, captchaRef, lastTokenValue, setLastTokenValue]);

  const props = {
    sitekey: process.env.REACT_APP_RECAPTCHA_SITE_ID,
    ref: captchaRef,
    onChange,
  };
  // this is for network error on recaptcha. should just set an error for the user. no action.
  if (onErrored) props.onErrored = onErrored;
  if(!process.env.REACT_APP_RECAPTCHA_SITE_ID) {
    return null;
  }
  return (<ReCAPTCHA {...props} />);
};

Recaptcha.defaultProps = {
};

Recaptcha.propTypes = {
  onChange: PropTypes.func.isRequired,
  token: PropTypes.any, // string or null
};

export default Recaptcha;
