import React, { Component } from "react";
import ReactDOM from "react-dom";
import Braintree from "braintree-web-drop-in";

class BraintreeDropIn extends Component {
  constructor(props) {
    super(props);
    this.instance = null;
    this.wrapper = React.createRef();
  }

  async componentDidMount() {
    this.instance = await Braintree.create({
      container: ReactDOM.findDOMNode(this.wrapper.current),
      ...this.props.options
    }).catch(err => console.error("error creating braintree", err)); // eslint-disable-line

    if (this.props.onNoPaymentMethodRequestable && this.instance) {
      this.instance.on(
        "noPaymentMethodRequestable",
        this.props.onNoPaymentMethodRequestable
      );
    }
    if (this.props.onPaymentMethodRequestable && this.instance) {
      this.instance.on(
        "paymentMethodRequestable",
        this.props.onPaymentMethodRequestable
      );
    }
    if (this.props.onPaymentOptionSelected && this.instance) {
      this.instance.on(
        "paymentOptionSelected",
        this.props.onPaymentOptionSelected
      );
    }

    if (
      this.props.onPaymentMethodRequestable &&
      this.instance &&
      this.instance.isPaymentMethodRequestable()
    ) {
      this.props.onPaymentMethodRequestable();
    }

    if (this.props.onInstance) {
      this.props.onInstance(this.instance);
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.shouldReset && nextProps.shouldReset && this.instance) {
      this.instance.clearSelectedPaymentMethod();
    }
    return true;
  }

  async componentWillUnmount() {
    if (this.instance) {
      await this.instance.teardown();
    }
  }

  render() {
    return <div ref={this.wrapper} />;
  }
}

export default BraintreeDropIn;
