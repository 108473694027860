import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./sectionTitle.css";

const SectionTitle = props => {
  const isActive = props.active || false;
  const isClickable = props.clickable || false;
  return (
    <div
      onClick={props.onClick}
      className={classnames({
        "section-title": true,
        "section-title-active": isActive,
        "section-title-disabled": !isActive,
        "section-title-clickable": isClickable,
        "mr-4": props.padRight
      })}
    >
      {props.children}
    </div>
  );
};

SectionTitle.defaultProps = {
  active: true,
  clickable: false,
  padRight: false,
  onClick: () => {}
};

SectionTitle.propTypes = {
  active: PropTypes.bool,
  padRight: PropTypes.bool,
  clickable: PropTypes.bool,
  onClick: PropTypes.func
};

export default SectionTitle;
