import React, { useEffect } from 'react';
import { Col, Fade, Row } from "reactstrap";

import "./EvervaultDropin.css";
import BillingAddressLine1 from '../billingAddressLine1';
import BillingAddressZip from '../billingAddressZip';
import BillingAddressCity from '../billingAddressCity';
import BillingAddressState from '../billingAddressState';
import BillingAddressCountry from '../billingAddressCountry';
import useEvervault from './useEvervault';
import ErrorAlert from '../../errorAlert';
import getEvervaultErrorData from '../../../helpers/getEvervaultErrorData';
import useStore from "../../../hooks/useStore";
import {observer} from "mobx-react";
import {PLATE_STATES} from "../../../helpers";

const EvervaultDropin = ({ paymentReady, cardRef }) => {
  const evervault = useEvervault()
  const { payment, violation } = useStore()

  const handleScriptLoad = () => {
    const evervaultInstance = new window.Evervault(
      process.env.REACT_APP_EVERVAULT_TEAM_ID,
      process.env.REACT_APP_EVERVAULT_APP_ID
    )

    const card = evervaultInstance.ui.card({
      theme: evervaultInstance.ui.themes.clean(),
      inputHeight: "40px",
      fontFamily: "avenir-next-regular, system, sans-serif",
    })

    card.mount("#evervault-card-fields")
    card.on("change", (payload) => {
      evervault.setIsValid(payload.isValid && payload.isComplete)
      payment.cardValidityStatusChange(payload.isValid && payload.isComplete)
      evervault.setCardScheme(payload.card.brand)
    })
    cardRef.current = card
    let selectedState = PLATE_STATES["IL"]
    if (violation.items.length > 0) {
      selectedState = PLATE_STATES[violation.items[0].plateState] || PLATE_STATES["IL"]
    }
    payment.setPaymentField("billingAddressState",selectedState)
    payment.paymentReadyStatusChange(true)
  }

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://js.evervault.com/v2"
    script.async = true
    script.onload = handleScriptLoad
    document.body.appendChild(script)
    evervault.setIsReady(true)
  }, [])

  return (
    <>
      <Fade in={!!payment.errorToast}>
        <ErrorAlert error={{ message: payment.errorToast }}  errorToggle={payment.clearErrorToast} />
      </Fade>
      <Fade in={!!payment.chicagoBotError}>
        <ErrorAlert error={getEvervaultErrorData(payment.chicagoBotError)}  errorToggle={payment.clearChicagoBotError} />
      </Fade>
      <div className="evervault-dropin-wrapper">
        <Fade in={paymentReady} className="evervault-dropin-fade">
          <Row>
            <Col>
              <div id="evervault-card-fields" />
            </Col>
          </Row>

          <div className="evervault-dropin-field-spacer"/>

          <Row>
            <Col>
              <BillingAddressLine1 />
            </Col>
          </Row>

          <Row>
            <Col>
              <BillingAddressCity />
            </Col>
            <Col>
              <BillingAddressState />
            </Col>
          </Row>

          <Row>
            <Col>
              <BillingAddressZip />
            </Col>
            <Col>
              <BillingAddressCountry />
            </Col>
          </Row>
        </Fade>
      </div>
    </>
  );
};


export default observer(EvervaultDropin)
