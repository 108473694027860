import React from "react";
import {
  Col,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarToggler,
  Collapse,
  Badge
} from "reactstrap";
import classnames from "classnames";
import "./header.css";
import WinItSvg from "../assets/svg/winit.svg";

let HEADER_ENV_BG_COLOR;
if (process.env.REACT_APP_ENV === "production") {
  HEADER_ENV_BG_COLOR = false;
} else if (process.env.REACT_APP_ENV === "staging") {
  HEADER_ENV_BG_COLOR = "#B32CB3";
} else if (process.env.REACT_APP_ENV === "development") {
  HEADER_ENV_BG_COLOR = "#2CB32C";
} else {
  HEADER_ENV_BG_COLOR = "#2CB3B3";
}

/* eslint react/jsx-no-target-blank: 0 */
const Header = props => (
  <Navbar expand="md" className="mb-4 py-3 flex-shrink-0 navbar-border-bottom">
    <div className="container">
      <Col>
        <NavbarBrand href="/" className="header-logo-text">
          <div>
            <span className="header-logo-text-pay-chicago">
              PAY {props.location.name.toUpperCase()}
            </span>
            <span className="header-logo-text-tickets">TICKETS</span>
          </div>
        </NavbarBrand>
        <div>
          <span className="header-logo-text-powered-by">powered by</span>
          <a
            style={{ textDecoration: "none" }}
            href="https://www.appwinit.com/"
            target="_blank"
            className="logo-hover"
          >
            <span className="header-logo-text-winit-logo">
              <img src={WinItSvg} alt="WinIt" />{" "}
              <b style={{ fontSize: "12px" }}>*</b>
            </span>
          </a>
        </div>
      </Col>
      <NavbarToggler onClick={props.toggle} />
      <Collapse isOpen={props.showMobileMenu} navbar>
        <Nav
          className={classnames({
            "ml-auto": true,
            "mobile-menu": props.isMobile
          })}
          navbar
        >
          <NavItem>
            <a href="/" className="nav-link">
              HOME
            </a>
            {/* </NavLink> */}
          </NavItem>
          <NavItem>
            <div className="nav-link" onClick={props.toggleServices}>
              SERVICES
            </div>
          </NavItem>
          <NavItem>
            <div className="nav-link" onClick={props.toggleContact}>
              CONTACT
            </div>
          </NavItem>
          {HEADER_ENV_BG_COLOR && (
            <Badge
              style={{ backgroundColor: HEADER_ENV_BG_COLOR, fontSize: 10 }}
              className="ml-4 py-1 px-2 d-flex align-items-center justify-content-center"
            >
              {process.env.REACT_APP_ENV.toUpperCase()}
            </Badge>
          )}
        </Nav>
      </Collapse>
    </div>
  </Navbar>
);

export default Header;
