import GLPN from "google-libphonenumber";
import * as stringTools from "./stringTools";

const PNF = GLPN.PhoneNumberFormat;
const phoneNumberUtil = GLPN.PhoneNumberUtil;

export const usFormat = phoneNumber => {
  if (!phoneNumber) return "";
  let result;
  try {
    const phoneUtil = phoneNumberUtil.getInstance();
    const number = phoneUtil.parse(phoneNumber, "US");
    result = phoneUtil.format(number, PNF.NATIONAL);
  } catch (error) {
    // console.error('error parsing phone number', error);
  } finally {
    return result || phoneNumber;
  }
};

export const cleanPhoneNumberInput = phoneNumberInput => {
  // console.error('clean input =>', phoneNumberInput);
  const replace = stringTools.replaceAll;
  const safeChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  const chars = phoneNumberInput.split("");
  const foundUnsafeChars = [];
  chars.forEach(char => {
    const isSafe = safeChars.includes(char);
    // console.error(char, isSafe ? 'IS SAFE!' : 'IS NOT SAFE!!!!');
    if (!isSafe) {
      foundUnsafeChars.push(char);
    }
  });

  // console.error('foundUnsafeChars.length', foundUnsafeChars.length);
  if (!foundUnsafeChars.length) return phoneNumberInput;
  let safePhoneNumber = phoneNumberInput;
  foundUnsafeChars.forEach(char => {
    safePhoneNumber = replace(safePhoneNumber, char, "");
  });
  // console.error('safePhoneNumber', safePhoneNumber);
  return safePhoneNumber;
};
