import axios from "axios";

const logToServer = async (data, method="info") => {
  let response

  try {
    response = await axios.post(
      "/.netlify/functions/logger",
      JSON.stringify({ data, method })
    )
  } catch (error) {
    console.error("logToServer() failed with error: ", error)
  }

  if (response && response.status !== 200) {
    console.error(`logToServer() failed with status ${response.status}`)
  }
};

export default logToServer;
