import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import { AES, enc } from "crypto-js";
import useStore from "../hooks/useStore";
import { STEPS, getPersistedApplicationState } from "../helpers";
import Search from "./search";
import Violations from "./violations";
import Payment from "./payment";
import Complete from "./complete";
import history from "../history";
import GoogleAnalytics from "../tracking/google";
import getRouteQuery from "../helpers/getRouteQuery";
import logToServer from "../helpers/logToServer";

const changePath = target => {
  if (history.location.pathname !== target) {
    history.push(target);
  }
};

const STEP_COMPONENT = {
  [STEPS.search]: Search,
  [STEPS.result]: Violations,
  [STEPS.payment]: Payment,
  [STEPS.done]: Complete
};

const STEP_ROUTE = {
  [STEPS.search]: "/search",
  [STEPS.result]: "/select",
  [STEPS.payment]: "/payment",
  [STEPS.done]: "/success?pc=" // query for insurance growthlink clickfeed - see comments in \src\components\clickFeed.js
};

const STEP_TRACKER = {
  [STEPS.search]: GoogleAnalytics.view.home,
  [STEPS.result]: GoogleAnalytics.view.ticketsList,
  [STEPS.payment]: GoogleAnalytics.view.paymentForm,
  [STEPS.done]: GoogleAnalytics.view.success
};

const RenderSteps = () => {
  const {
    general: generalStore,
    payment: paymentStore,
    person: personStore,
    search: searchStore,
    violation: violationStore
  } = useStore();

  const location = useLocation();
  const query = getRouteQuery(location);

  if (query && query.plateNumbers) {
    const { plateNumbers } = query;
    const plateString = AES.decrypt(
      plateNumbers,
      process.env.REACT_APP_CRYPTOJS_SECRET
    ).toString(enc.Utf8);

    let plates = [];
    if (
      plateString &&
      plateString[0] === "[" &&
      plateString.slice(-1) === "]"
    ) {
      const cleanedPlates = plateString.replace(/"/g, "").slice(1, -1); // remove the brackets + doublequotes CryptoJS puts there
      plates = cleanedPlates.split(",");
    }

    if (plates.length) {
      violationStore.getTicketsByPlates(plates);
    }
  }

  useEffect(() => {
    const asyncWrapper = async () => {
      const paymentFormResponse = query.paymentResult; // Query paremeter on our URL set by CKO hosted payment page when it sends us back to this domain
      const ckoSessionId = query["cko-session-id"];
      if (paymentFormResponse && ckoSessionId) {
        const ckoPaymentId = await paymentStore.getCkoPaymentIdFromSessionId(
          ckoSessionId
        );
        const persistedState = await getPersistedApplicationState(ckoPaymentId);
        if (!persistedState) return false;

        const { general, payment, person, search, violations } = persistedState;
        generalStore.loadGeneralPersistedState(general);
        paymentStore.loadPaymentPersistedState(payment);
        personStore.loadPersonPersistedState(person);
        searchStore.loadSearchPersistedState(search);
        violationStore.loadViolationsPersistedState(violations);

        switch (paymentFormResponse) {
          case "success":
            // User had successful checkout on CKO hosted payment page
            generalStore.setStep(STEPS.done);
            paymentStore.savePaymentDetails(ckoSessionId);
            break;
          case "cancel":
            // User cancelled on CKO hosted payment page, so pick up where we left off
            generalStore.setStep(STEPS.payment);
            break;
          case "failure":
            // Payment failed on CKO api - go back to payment page & show the user the error
            paymentStore.getFailureMessage(ckoSessionId);
            generalStore.setStep(STEPS.payment);
            paymentStore.setSubmitted(false);
            break;
          default:
            logToServer(
              `Received unexpected response from CKO hosted page: ${paymentFormResponse} - CKO Session ID: ${ckoSessionId}`,
              "warn"
            );
        }
      }
    };
    asyncWrapper();
  }, [
    generalStore,
    paymentStore,
    personStore,
    query,
    searchStore,
    violationStore
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    changePath(STEP_ROUTE[generalStore.step]);
    if (STEP_TRACKER[generalStore.step]) STEP_TRACKER[generalStore.step]();
  }, [generalStore.step]);

  const Page =
    STEP_COMPONENT[generalStore.step] || STEP_COMPONENT[STEPS.search];
  return <Page />;
};

export default observer(RenderSteps);
