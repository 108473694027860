import { types } from "mobx-state-tree";

import GeneralStore from "./GeneralStore";
import PaymentStore from "./PaymentStore";
import PersonStore from "./PersonStore";
import SearchStore from "./SearchStore";
import ViolationStore from "./ViolationStore";

const AppStore = types.model("AppStore", {
  general: types.optional(GeneralStore, {}),
  payment: types.optional(PaymentStore, {}),
  person: types.optional(PersonStore, {}),
  search: types.optional(SearchStore, {}),
  violation: types.optional(ViolationStore, {})
});

const initialStore = {
  general: {},
  payment: {},
  person: {},
  search: {},
  violation: {}
};

export const createStore = (data = {}) =>
  AppStore.create({ ...initialStore, ...data });

const store = createStore();
export default store;
