import React from "react";

import "./describeSite.css";

import SectionTitle from "./sectionTitle";

const DescribeSite = (props) => (
  <div className="pt-5 pb-5">
    <SectionTitle active>SAFE AND SECURE</SectionTitle>
    <h2>Pay Your {props.location.name} Parking Tickets Now</h2>
    <div className="describe-site-offering">
      A secure and reliable option to pay your tickets online.
    </div>
  </div>
);

export default DescribeSite;
